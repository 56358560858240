export default {
  tabbar: {
    首页: 'Casa',
    市场: 'Mercado',
    任务: '任务',
    团队: 'Time',
    我的: 'Minha'
  },
  navbar: {
    充值: 'Depósito',
    提现: 'Retirar-se',
    帮助: 'FAQ',
	推广: 'Receita de Ads',
    分享: 'Convidar',
    活动: 'Bónus',
    游戏: 'Jogo',
  },	 
  payment: {
    付款方式详情: 'Dados da forma de pagamento',
    银行卡: '银行卡',
    钱包地址: 'Endereço de carteira',
    交易密码: 'Senha de transação',
    保存: 'Salve'
  },
  market: {
    市场: 'Mercado',
    名称: 'Mome',
    价格: 'Preço',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: 'Eu vejo'
  },
  邀请好友注册领取佣金:"Convide amigos para se registrar e receber comissões",
  领取:"Receber",
  未满足条件:"Condição não atendida",
  总收入:"Renda total",
  投资建议:"※Realize um plano de investimento com juros compostos para obter retornos mais substanciais",
  语言切换: 'Troca de idioma',
  POS区块节点挖矿: 'Detalhes quantitativos de negociação Ai',
  节点类型: 'Robô\nTipo',
  加入价格: 'Comprar\nPreço',
  质押资金: 'POS\nfundos',
  质押周期: 'Período\n(dias)',
  每日收入: 'Renda\nDiária',
  累计积分: 'Pontos\nAcumulados',
  代理等级: 'Agente\nNível',
  下级折扣比: 'Subordinado\nDesconto',
  代理折扣比: 'Agente\nDesconto',
  下属等级: 'Subordinado\nNível',
  代理佣金比率: 'Agente\nComissão\nTaxa',
  下属每日收入: 'Subordinado\nDiário\nReceita',
  代理佣金: 'Agente\nComissão',
  引用对象: 'Referência\noobjeto',
  积分等级折扣规则: 'Regras de desconto de nível de pontos',
  积分等级折扣规则介绍: 'Os usuários que participarem com sucesso da negociação quantitativa Ai receberão pontos de conta correspondentes, e seus usuários superiores de agente também podem aumentar os pontos correspondentes (aplicável a 15% dos subordinados diretos).Quando o total de pontos de conta atender às condições correspondentes, o nível do agente conta será melhorada., Contas de agentes em diferentes níveis podem obter diferentes proporções de descontos de preço. Os detalhes dos descontos de pontos relevantes são os seguintes:',
  积分等级折扣规则介绍2: 'Usuários com relacionamentos diferentes (diretos) receberão descontos e descontos em nível de agente com base na proporção da participação dos subordinados nas transações quantitativas da Ai. Outros executivos seniores também receberão níveis de desconto proporcionais aos seus próprios agentes. Descontos de preço para participação em transações quantitativas de Ai: Os usuários com níveis de agente correspondentes receberão direitos preferenciais de nível de agente correspondentes ao participarem de transações quantitativas de Ai.',
  团队: 'Time',
  代理佣金制度: 'Sistema de comissão da agência',
  代理佣金说明详情: 'Proporção de agentes LV1 obtida por A: 8%, proporção de agentes LV2 obtida por A: 3%, proporção de agentes LV3 obtida por A: 2%. Exemplo: O lucro diário de B, C e D é: 100 USD, e a recompensa do agente LV1 obtida por A: 8%*100=8 USD. A recompensa do agente LV2 obtida por A: 3%*100=3 USD. A recompensa do agente LV3 obtida por A: 2%*100=2 USD. A receita total da comissão é: 8+3+2=13 USD.',
  代理结构图示: 'Diagrama de estrutura de proxy',
  代理结构图示说明: 'A representa você, A convida B (o subordinado de A é B), B convida C (o subordinado de B é C) e C convida D (o subordinado de C é D). A pode ter vários subordinados (B1, B2, B3, B4, B5, B6, etc.), B (B1, B2, B3) também pode ter vários subordinados (C1, c2, c3, c4, c5, c6, etc. ) CD...',
  高级代理佣金说明: 'Instruções da Comissão do Agente Sênior',
  高级代理佣金说明详情: 'A representa você, A convida B (o subordinado de A é B), B convida C (o subordinado de B é C) e C convida D (o subordinado de C é D). O agente superior obterá receita de comissão de agência diferente (%) de acordo com a proporção da quantidade total de mineração de nó envolvida em sua própria conta e contas subordinadas. A compara a renda diária dos membros subordinados para obter a proporção correspondente de recompensas da agência. Os nós participantes devem estar em funcionamento. A receita da agência é fornecida pelo INGSAI MINING para todas as despesas financeiras e não afetará a renda diária dos membros subordinados normais.',
  高级代理佣金说明详情2: 'Exemplo: A quantidade total de mineração de agentes seniores (A)>30% da quantidade total de mineração de membros inferiores (B/C/D) (Exemplo: A>30% de B/C/D)',
  积分: 'Pontos',
  规则: 'Regra',
  会员级别: 'Nível de membro',
  经验: 'Pontos totais',
  代理信息: 'Informações do membro',
  更多: 'Mais',
  团队奖励: 'Comissão de time',
  昨天: 'Ontem',
  本星期: 'Essa semana',
  全部: 'Todos',
  机器人说明: 'Os usuários do Plus500Ai podem comprar vários pacotes de negociação quantitativos ao mesmo tempo, e o robô NO.1 só pode comprar 10 vezes por conta. Lembre-se: não se esqueça de clicar para ativar a estratégia após adquirir o robô quantitativo, caso contrário não haverá receita. O tempo de validade do robô quantitativo é calculado com base no momento da compra. Ative a estratégia de negociação quantitativa a tempo. Se o robô quantitativo não for ativado após expirar, você perderá o custo de compra do pacote de estratégia de negociação quantitativa! !!Todos os usuários que participam da negociação quantitativa da Plus500Ai, por favor leiam com atenção. Este regulamento! !',
  会员体验: 'Experiência de membro',
  机器人采购: 'Compra de Robôs',
  机器人启动押金: 'Quantidade de POS do nó',
  机器人礼物: 'Presente do nodo',
  机器人订单: 'Ordem dos nós',
  一次性总和: 'Mostra',
  总计: 'Total',
  
  被激活: 'Já POS',
  工作中: 'ATIVADO',
  暂停: 'Suspensão do POS',
  结束: 'EXPIRAR',
  刷新: 'Refrescar',
  来源: 'A partir do',
  价格: 'Preço do Pacote Robô',
  启动押金: 'Valor do POS',
  剩余时间: 'Tempo restante',
  激活时间: 'Hora de ativação',
  号: 'Nº',
  我的资产: 'Meus ativos totais',
  退出: 'Terminar sessão',
  邀请码: 'Código de Convite',
  我的钱包余额: 'Meu saldo de carteira',
  我的机器人仓库: 'Minha Lista de Robôs',
  收益面板: 'Painel de ganhos',
  去提款: 'Pedir retirada',
  昨天代理返利: 'Comissão da agência ontem',
  累计代理返利: 'Comissão cumulativa da agência',
  今天机器人收益: 'Lucro comercial de hoje',
  累计机器人收益: 'Renda comercial acumulada',
  累计机器人回扣: 'Comissão de negociação acumulada',
  累计总回报: 'Renda total cumulativa',
  自动订单机器人数量: 'Robô comercial quantitativo ativado',
  我的付款方式: 'Minha forma de pagamento',
  个人收入记录: 'Renda pessoal',
  团队收入记录: 'Renda da equipe',
  记录详情:'Registrar detalhes',
  充值记录: 'Registros de depósito',
  我的团队成员: 'Membros da minha equipe',
  活动中心: 'Introdução às Regras',
  修改登录密码: 'Configuração de senha de login',
  交易密码管理: 'Configuração de senha de transação',
  机器人: 'Estratégia de Negociação Ai',
  机器人性能: 'Painel do Robô',
  机器人价格: 'Preço da estratégia de negociação',
  有效期: 'Período de validade',
  钱包地址: 'Endereço de carteira',
  天: 'dia',
  去购买: 'Comprar',
  买个机器人: 'Compre o robô comercial Quant',
  我的钱包余额: 'Saldo da minha conta',
  预计日收益: 'Renda diária estimada',
  启动保证金: 'Depósito inicial',
  请输入购买数量: 'Por favor, insira a quantidade de compra',
  个机器人: ' Robô comercial Quant',
  机器人购买后需要激活才能工作: 'Depois de comprar um nó, é necessário o valor correspondente de POS para começar a se beneficiar',
  机器人激活需要从余额中扣除相应的启动押金:
    'A mineração de POS do nó precisa deduzir o valor POS correspondente do saldo da conta',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'A quantidade de POS pode ser devolvida imediatamente quando a mineração de POS do nó for suspensa',
  购买: 'Comprar',
  点击去了解我们: 'Negociação Quantitativa',
  购买机器人: 'Compre um robô',
  快速入口: 'Entrada rápida',
  '日/个人受益': 'Dia/benefício pessoal',
  购买教程: 'Lista de robôs de negociação AI',
  激活金额: 'TAXA DE POS',
  订单数量: 'Quantidade de pedido',
  我们的合作伙伴: 'Nossos parceiros de criptomoeda',
  提款: 'Retirar dinheiro',
  提款金额: 'Quantidade retirada',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: 'Instruções de retirada',
  说明1: '1.Você pode selecionar com um clique ou inserir manualmente o valor da recarga',
  说明2: '2.Recomenda-se usar USD (TRC20), a taxa de transferência é a mais baixa',
  说明3: '3.O horário de atendimento ao cliente é de segunda a sexta-feira AM8 a PM6 Horário da América Central',
  说明4: 'Os usuários que usam USD para recarregar precisam fazer o upload da captura de tela correta da transferência bem-sucedida e do TXID, e a conta será recebida com sucesso depois que a plataforma passar na revisão',
  说明5: '4.A recarga mínima é de 8 USD, o que é conveniente para o sistema revisar e processar rapidamente a conta',
  说明6: '5.Se você tiver alguma dúvida sobre a recarga, entre em contato com o atendimento ao cliente on-line a tempo para obter assistência',
  确认提款: 'Confirmar retirada ',
  请输入手机号码:'Número de telefone',
  请输入提现金额: 'Valor da retirada ',
  提现费:'Taxa de saque',
  提现须知: 'Por favor, leia as regras de retirada cuidadosamente',
  TIPS1: '1:O tempo de revisão de retirada diária é de segunda a sexta AM8 a PM6. Os saques podem ser feitos aos finais de semana, e o horário de revisão do saque é segunda-feira',
  TIPS2: '2:Sistema 7X24 horas, saque dinheiro a qualquer momento, chegue à conta em 24 horas no máximo e chegue à conta em 5 minutos no mínimo',
  TIPS3: '3:Horário de atendimento ao cliente online: Segunda a sexta AM8 a PM6',
  TIPS4: '4:Devido ao custo da transferência em USD, a plataforma cobra uma taxa de saque quando o usuário saca dinheiro',
  TIPS5: '5:O valor mínimo de retirada é de 10 USD',
  钱包余额: 'Balanço da carteira',
  输入数量:"Digite a quantidade",
  数量:"Quantidade",
  手机号码:"Número de telefone",
  手机号码已存在:"O número do celular foi registrado",
  邮箱:"Endereço de email",
  输入手机号码: 'Digite o número do celular',
  请输入登录密码: 'Senha de login',
  登录: 'Conecte-se',
  输入账号: 'Digite o número da conta',
  密码: 'Senha',
  点击注册: 'Não tem uma conta？Crie uma agora!',
  忘记密码: 'Esqueça a senha',
  重新登录: 'Faça o login novamente',
  密码找回成功: 'Senha recuperada com sucesso',
  找回成功请重新登录: 'Recuperado com sucesso, faça o login novamente',
  确认: 'Confirme',
  发送验证码: 'Enviar o código de verificação',
  再次输入密码: 'Confirme sua senha',
  输入验证码: 'Digite o e-mail CAPTCHA',
  输入手机验证码:"Insira OTP móvel",
  输入手机号: 'Digite numero de celular',
  输入密码: 'Senha de login',
  快速注册: 'Registro rápido',
  注册: 'Registro',
  立即登录: 'Já possui uma conta, clique para entrar',
  请输入邮箱: 'Insira seu endereço de e-mail do Gmail',
  输入用户名: 'Entre com seu nome de usuário',
  请输入邀请码: 'Por favor insira o código de convite',
  返回: 'Retorno',
  密码找回失败: 'Falha na recuperação da senha',
  输入的信息有误: 'A informação inserida está incorreta',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"O número de telefone não pode ficar em branco",
	账号不存在:"Conta não existe",
	发送成功:"Enviado com sucesso",
	今日收益:"(renda atual)",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Olá, se você encerrar o POS no meio do caminho, o sistema cobrará uma taxa de 5% de POS. Lembrete especial: Após a suspensão do nó gratuito da experiência, você não poderá obtê-lo novamente, nem poderá obter nenhuma taxa! !",
	我同意:"Eu aceito",
	用户账号ID:"ID da conta",
	取消:"Cancelar",
	日收益:"Renda diária",
	请输入购买数量:"Por favor, insira a quantidade de compra",
	数量不能为空:"A quantidade não pode estar vazia",
	加载中:"Carregando...",
	唯一码:"Codificação de Robô",
	未激活:"INATIVO",
	激活:"Estratégia inicial",
	购买时间:"Hora da compra",
	"钱包余额不足，激活该机器人失败":"Saldo de conta insuficiente, incapaz de realizar a mineração de POS de nós",

	运行时长:"Tempo de execução",
	签约购买:"Compra de saldo",
	系统赠送:"Presente do sistema",
	状态:"Estado",
	正常:"Normal",
	我的机器人:"Meu Robô Comercial Quant",
	一级会员:"Nível 1",
	二级会员:"Nível 2",
	三级会员:"Nível 3",
	人:"",
	充值客服:"Recarregue o atendimento ao cliente",
	充值:"Recarrega",
	提现:"Retirar-se",
	提款密码:"Senha de saque",
	设置交易密码:"Definir senha da transação",
	登录密码:"Senha de login",
	请输入交易密码:"Digite a senha da transação",
	再次请输入交易密码:"Confirme a senha da transação",
	确认: 'Confirme',
	手机号码不能为空:"O número de telefone não pode ficar em branco",
	两次密码不一致:"As duas senhas não combinam",
	请输入验证码:"Digite o código de confirmação",
	操作成功:"Operação bem-sucedida",
	"用户名或密码不正确,登录失败":"O nome de usuário ou a senha estão incorretos, o login falhou",
	登录成功:"Login bem-sucedido",
	充值说明:"Instruções de recarga",
	请先设置支付密码:"Por favor, defina a senha da transação primeiro",
	复制成功:"Copie com sucesso",
	冻结机器人做单本金:"Congelar fundos quantitativos",
	待审核:"Revisão pendente...",
	成功:"Sucesso",
	失败:"Falhou",
	审核中:"Sob revisão",
	在线充值:"Recarregue on-line",
	描叙:"Descreva",
	POS节点挖矿LV0:" mineração de nó POS-LV0",
	POS节点挖矿LV1:" mineração de nó POS-LV1",
	POS节点挖矿LV2:" mineração de nó POS-LV2",
	POS节点挖矿LV3:" mineração de nó POS-LV3",
	POS节点挖矿LV4:" mineração de nó POS-LV4",
	POS节点挖矿LV5:" mineração de nó POS-LV5",
	银行卡提现:"银行卡提现",
	USD提现:"Retirada do USD",
	三级代理:"Agente de Nível 3",
	一级代理:"Agente de Nível 1",
	二级代理:"Agente de Nível 2",
	一级:"Nível 1",
	二级:"Nível 2",
	三级:"Nível 3",
	做单:"Mineração de nós",
	登录失败:"Falha no login",
	请勿重复操作:"Não repita operações frequentes",
	邀请码不正确:"Código de convite está incorreto",
	团队返佣:"Comissão de time",
	购买机器人返佣:"Comissão de Robôs",
	本金返还:"POS devolvida",
	佣金收入:"Comissão de renda",
	时间:"horário",
	机器人做单返还:"Devolução do valor POS",
	涨幅:"Alteração",
	市场:"Mercado",
	客服:"Serviço",
	验证码错误:"Erro no código de verificação",
  付款方式:"Endereço de saque USD",
	我的留言:"Minha mensagem",
  暂无数据: 'Nenhum dado',
  银行卡:'Cartão do banco',
  修改: 'Modificar',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Certifique-se de que o endereço da sua carteira esteja correto. Se o seu endereço for digitado incorretamente e o dinheiro não puder ser recebido, entre em contato com o atendimento ao cliente on-line a tempo para obter assistência',
 连接借记卡:"连接借记卡",
 银行名称:'Nome do banco',
 姓名姓氏: 'Nome',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*Lembrete importante: as informações da conta bancária devem ser verdadeiras e válidas antes que você possa sacar dinheiro。',
 帮助中心:'Central de ajuda',
 会员须知:'Aviso de Sócio',
 '24小时内使用USD实时取款（推荐）':'1. Use USD para retirada em tempo real dentro de 24 horas (recomendado)',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Eu vejo',
 链接点击:'Link de registro de referência',
 确定:'Confirmar',
 建立自己的团队邀请:'Construir uma equipe',
 此账号已冻结:"Esta conta foi congelada",
 手机号存已存在:"O número do celular já existe",
 注册账号已存在:"Conta registrada já existe",
 请确定新密码:"Confirme a nova senha",
 请再次输入登录密码:"Digite a senha de login novamente",
 密码长度不能少于6位:"O comprimento da senha não pode ser inferior a 6 caracteres",
 加入:'Junte-se',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "O nó que você vendeu foi aceito pela outra parte e o produto da venda foi transferido para sua conta, verifique。",
 	"你出售的机器人对方已拒绝接受":"O nó que você vendeu foi rejeitado pela outra parte",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"A conta bancária foi usada",
 	USD_TRC20提现:'Retirada de USD_TRC20',
 	USD_ERC20提现:'Retirada de USD_ERC20',
 	银行卡提现:'Retirada de conta bancária',
 	邀请赠送:'Bônus de convite',
 	机器人返佣:'Comissão de Robôs',
 	升级:' compra bem sucedida ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "Número da transação",
   消息详情:"Detalhes da mensagem",
   个人收益累计:"Renda pessoal acumulada",
   今日代理收益:"Receita da agência hoje",
   代理累计收益:"Receita cumulativa da agência",
   截图保存推荐给朋友:"Compartilhe seu link de registro de indicação e convide amigos para se juntarem à plataforma Plus500Ai para ganhar recompensas de comissão de renda da equipe. A comissão de rendimentos para membros da equipe de terceiro nível é de 8%-3%-2%. A comissão da equipe é baseada nos dados de renda diária dos membros de nível inferior e será automaticamente liquidada e inserida no saldo da sua conta todos os dias após as 00h00.",
   复制:"Cópia de",
   充值提示1:"Dica 1: A recarga mínima é de 10 USD, pode recarregar, senão não será creditado",
   充值提示2:"Dica 2: A recarga precisa ser confirmada na rede. Após a recarga bem-sucedida, espera-se que ela chegue ao saldo da carteira em cerca de um minuto。",
   请输入正确的充值金额:"Valor do depósito ≥ 8 USD",
   推荐:"Recomendar",
   充值金额:"Valor da recarga",
   请上传付款截图:"Faça upload de uma captura de tela do pagamento bem-sucedido",
   交易号: 'Número de hash da transação',
   充值ID提示:"Cole o número de série da transação Txid",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Certifique-se de que o endereço da carteira está correto. Qualquer perda causada por falha no recebimento de dinheiro devido ao preenchimento incorreto do endereço da carteira será suportada pelo usuário.",
	上传凭证:"Carregar comprovante de pagamento",
	充值确认: "Enviar",
	不能为空:"Não pode estar vazio",
  绑定钱包地址:'Vincular endereço da carteira',
	备注:"comentário",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"Digite seu nome",
	银行卡号:"Conta bancária",
	添加银行卡:"Vincular conta bancária",
	请选择银行:"Selecione um banco",
	请输入钱包地址:"Insira o endereço da carteira",
	钱包地址:"Endereço de carteira",
	"密码错误次数过多,请等待x秒后再试":"Muitas senhas erradas, aguarde 600 segundos e tente novamente",
	"此账号已冻结,请联系客服":"Esta conta foi congelada, entre em contato com o atendimento ao cliente",
	手机号格式错误:"Número de telefone incorreto",
	"取款须知":"Aviso de Retirada",
	"须知1":"1. Use USD-TRC20 para retirada em tempo real dentro de 24 horas (recomendado)",
	"须知2":"2. Retirada no sábado e domingo",
	"须知3":"*Os usuários podem sacar dinheiro no sábado e domingo",
	"须知4":"*As retiradas de fim de semana chegarão na segunda-feira, das 10AM às 8PM",
	我知道了:"Eu vejo",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	请输入请款金额:"Insira o valor da retirada",
	已绑定:"vinculado",
	去设置:"vá para as configurações",
	注册成功:"Registro bem sucedido",
	汇率:"taxa de câmbio",
	请输入正确的提现金额:"Por favor, insira o valor de retirada correto",
	提款记录:"Histórico de retirada",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Por favor, vincule o endereço da carteira primeiro",
	输入邮箱:"Digite o e-mail",
	邮箱已存在:"Caixa de correio já existe",
	注册邮箱已存在:"E-mail cadastrado já existe",
	指定用户不存在:"O usuário especificado não existe",
	今日涨幅:"Alteração",
	恭喜新用户:"Parabéns novo usuário ",
	快捷充币:"Depósito",
	快速买USD:"Recarga de USD",
	在线支付:"Pagamento online",
	自动到账:"Conta automática",
	线下支付:"Pagamentos off-line",
	联系客服获取验证码:"Entre em contato com o atendimento ao cliente para obter o código de verificação",
	获取:"Obtivermos",
	退出成功:"Sair com sucesso",
	不能低于最小充值:"Não pode ser inferior à recarga mínima",
	不能低于最小提现金额:"Não pode ser inferior ao valor mínimo de retirada",
	最小提现:"Retirada mínima",
	设置成功:"Definido com sucesso",
	官方充币:"Depósito do sistema",
	银行卡充值:"Recarga bancária",
	等待审核:"Esperando revisão",
	可提现金额不足:"Valor insuficiente disponível para retirada",
	未经授权:"Não autorizado",
	交易密码不正确:"A senha da transação está incorreta",
	提现次数不足:"Tempos de saque insuficientes",
	官网:"官网",
	账户USD不足:"O saldo do USD da conta é insuficiente",
	激活成功: "POS bem-sucedida",
	操作成功: "Operação bem-sucedida",
	修改成功:"Modificado com sucesso",
	注册说明:"Mobile OTP e Email CAPTCHA só precisam escolher um método para verificar e você pode se registrar com sucesso",
	弹窗内容:
	"Bem-vindo à plataforma de negociação quantitativa Plus500Ai. Cada usuário pode usar o bônus de registro de 1 USD para comprar o pacote de estratégia de negociação de robô quantitativo NO.0, que é válido por 1 dia e ganhar 0,5 USD gratuitamente. Outros pacotes de estratégia de negociação quantitativa exigem o uso de taxas correspondentes. Depois que os usuários compram o robô, eles precisam ativar a estratégia de negociação quantitativa antes que ele possa começar a funcionar automaticamente. Cada estratégia de negociação corresponde a um período de validade diferente. Se o usuário não clicar para ativar a estratégia após adquirir os pontos do robô correspondentes, você não receberá nenhuma receita comercial.",
	标题1:'1.Introdução da plataforma Plus500',
	标题2:'2. Vantagens da negociação quantitativa de IA',
	标题3:'3. Por que escolher Plus500Ai?',
	标题4:'4. Como aderir à Plus500Ai',
	标题5:'5.Perspectivas de mercado Plus500Ai',
	标题6:'6. Modelo de receita Plus500Ai',
	标题7:'7.Regulamentos de Depósito e Retirada',
	标题8:"Jogabilidade de Batalha Real",
	内容8:"Existem 8 salas no jogo Battle Royale na plataforma Plu500Ai. Os jogadores podem escolher qualquer sala entre as 8 salas para colocar moedas de ouro. Após o término da contagem regressiva do sistema, um assassino aparecerá no sistema de jogo. O assassino entra aleatoriamente em uma sala e elimina as pessoas na sala. Outros jogadores podem compartilhar as moedas de ouro nesta sala e obter lucros. A jogabilidade do jogo Battle Royale é relativamente curta, com um jogo durando apenas alguns minutos, para que os jogadores possam jogá-lo em seu tempo livre. A quantidade de divisão = o número total de quartos mortos pelo assassino neste período. Valor real da ação = valor da ação * 0,9 (excluindo a taxa de destruição de 0,1). Proporção de participação = valor real da participação / valor total da aposta em outras salas. Valor vencedor = proporção de compartilhamento * valor real da aposta. 1 moeda de ouro = 1 USD e é necessário um mínimo de 10 USD para participar do jogo Battle Royale.",
	内容1:"A plataforma de negociação Plus500 é fornecida pela Plus500SEY Ltd. Assim, a Plus500SEY Ltd é a emissora e vendedora dos produtos financeiros descritos ou oferecidos neste site. Plus500SEY Ltd é uma empresa sediada em Seychelles (Empresa nº 8426415-1) com escritórios em Victoria. A empresa é autorizada e supervisionada pela Autoridade de Serviços Financeiros das Seychelles (FSA) com número de licença SD039 e fornece Contratos por Diferença (CFD) em vários produtos básicos. A empresa é um fornecedor de CFD em rápido crescimento que oferece atualmente um portfólio de mais de 2.800 instrumentos financeiros. Plus500SEY Ltd é uma subsidiária da Plus500 Ltd, que está listada no Mercado Principal da Bolsa de Valores de Londres e tem sede em Haifa.",
	内容2:'A vantagem do comércio quantitativo totalmente automatizado de IA é usar ferramentas racionais para operar transações de acordo com as configurações do modelo.O robô não tem alterações emocionais e pode superar a natureza humana, a ganância e o medo humanos e muitas outras deficiências e fraquezas no processo de operação. As operações manuais são frequentemente afectadas pela ascensão e queda do mercado e por vários outros factores, e não podem funcionar de acordo com o melhor modelo de lucro durante muito tempo.',
	内容3:'A Plus500Ai fortaleceu gradualmente a sua posição como um grupo fintech multiativos, expandindo os seus produtos principais em mercados novos e existentes, lançando novos produtos comerciais e financeiros e aprofundando o seu envolvimento com os clientes. Proteja seus ativos e suas chaves de criptografia com tecnologia de segurança líder do setor. A Plus500Ai também oferece custódia global, o que significa que somos regulamentados, auditados e segurados até US$ 250 milhões. Distribua seu capital com o objetivo não apenas de manter ativos, mas de colocá-los para trabalhar. Plus500Ai permite que você distribua seus fundos de diversas maneiras, desde negociação quantitativa de IA até serviços primários clássicos, dependendo da sua tolerância ao risco. Plataforma online para fornecer mais serviços aos seus próprios usuários sem contratar engenheiros de blockchain adicionais.',
	内容4:"Desde o estabelecimento da Plus500Ai, existe apenas um site oficial, WWW.PLUS500AI.COM. Todos os usuários precisam fazer login no site oficial para baixar o APP, pois outros sites não são da nossa empresa. Para se cadastrar, você precisa enviar o número do celular do usuário, endereço de e-mail e código de convite do recomendador para se cadastrar rapidamente. Todas as comunicações com os clientes são feitas por escrito, via e-mail ou chat online. Para entrar em contato com a Plus500 por e-mail, preencha o formulário na página Fale Conosco (Formulário de Solicitação). Após enviar o formulário de inscrição, a Plus500 enviará uma resposta por e-mail diretamente para o endereço de e-mail que você especificou no formulário de inscrição. Se você tiver alguma dúvida, entre em contato com o atendimento ao cliente.",
	内容5:'A Plus500Ai opera em vários produtos, dispositivos e países para oferecer aos clientes oportunidades de negociação financeira confiáveis ​​e intuitivas. Sempre buscamos a excelência em nossos produtos e equipes. Com tecnologia proprietária avançada e uma abordagem centrada no cliente, nossos usuários recebem uma experiência de serviço comercial de primeira classe.',
	内容6:"Plus500Ai fornece estratégias de negociação quantitativas profissionais para o mercado de criptomoedas por uma equipe de analistas de dados seniores.Os usuários que investem no robô de negociação quantitativa Ai podem obter lucros comerciais quantitativos confiando nas estratégias de negociação combinadas com o robô. O robô de negociação quantitativa Ai analisa ciclicamente o tempo de negociação ideal com base na estratégia de negociação de mercado da Plus500Ai e obtém lucros comerciais quantitativos através do modelo de compra e venda de baixo nível a preços elevados. Plus500Ai fornece aos usuários investidores estratégias de negociação quantitativa ideais todos os dias, e a plataforma extrai 5% dos lucros quantitativos da receita dos usuários como fundos operacionais da plataforma.",
	内容7:'Plus500Ai atualmente suporta depósitos e retiradas em USD, e haverá um anúncio no site oficial se outros canais forem abertos no futuro. O depósito e a retirada mínimos são de 10 USD. O depósito e a retirada mais rápidos ocorrem em 5 minutos e os mais lentos em 24 horas. Não há limite de tempo de saque do usuário, você pode sacar dinheiro a qualquer momento. As retiradas chegam dentro de 5 minutos a 24 horas em dias úteis normais. As retiradas de fim de semana serão processadas na segunda-feira.',
	BSC提现:"USD_BSC retirada",
	社区Ads收益:"Renda de Ads comunitários",
	Ads收益区间:"Receita de Ads 1-1000 $",
	申请Ads费用:"Taxa de Ads de aplicativo",
	Ads促销地址:"URL da promoção de Ads",
	填写Ads促销地址:"Preencha o URL da promoção do Ads",
	申请Ads费用须知:"Instruções para solicitar taxas de Ads",
	须知A:"1. Os usuários gravam vídeos promocionais e os carregam em seus próprios canais do YouTube, Facebook ou outras comunidades para promoção de anúncios, solicitam um bônus de $ 1 a $ 1.000, e a plataforma revisará seu material de vídeo de anúncios 3 dias após o envio do aplicativo e colocará os anúncios bônus na conta de acordo com o efeito da promoção do vídeo Saldo da sua conta, vídeos sem efeitos promocionais não podem ser revisados",
	须知B:"2. O mesmo vídeo promocional de anúncios no mesmo canal só pode solicitar um bônus uma vez, e novos materiais de vídeo promocional podem ser lançados uma vez por semana. Não envie solicitações repetidas maliciosas. Se você enviar repetidamente solicitações de anúncios não feitas por você na plataforma ou materiais de anúncios que não forem revisados, sua conta será banida após o sistema detectar",
	须知C:"3. O material de vídeo de anúncios gravado pelo usuário precisa ter uma introdução básica da plataforma e uma explicação da função da plataforma. O tempo de vídeo não é inferior a 5 minutos. Quanto mais inscritos em seu canal, melhor o efeito promocional do vídeo e maior o Taxa de anúncios que você pode solicitar. As taxas de anúncios específicas são as seguintes, tomando o YOUTUBE como exemplo:",
	须知D: "Assinante 0-2K: valor do aplicativo 1-20 $",
	须知D1:"Assinante 3K-10K: valor da inscrição 21-40$",
	须知D2:"Assinante 11K-30K: valor da inscrição 41-100$",
	须知D3:"Assinante 31K-50K: valor da inscrição 101-300$",
	须知D4:"Assinante 51K-100K: valor do aplicativo 301-1000$",
	须知D5:"4. O sistema analisará e processará as solicitações de bônus de anúncios todos os domingos, e os usuários qualificados receberão bônus enviados pelo sistema.",
	请求审核:"Solicitar revisão",
	申请金额:"Valor da Aplicação",
	URL促销地址:"Promoção de URL",
	提交时间:"Tempo de envio",
	需要更新的银行卡不存在:"O cartão bancário a atualizar não existe",
	银行卡提现:"Saque em dinheiro com cartão bancário",
	预计收入:"Renda estimada",
	总投资:"Investimento total",
	购买价:"preço de compra",
	邀请:"Convidar",
	成员:"membros",
	积分等级折扣规则介绍3:"Este valor de desconto só é válido até 31 de dezembro de 2023, após 1 de janeiro de 2024 haverá um novo reajuste.",
	LV1佣金:"Comissão de NÍVEL1",
	LV2佣金:"Comissão de NÍVEL2",
	LV3佣金:"Comissão de NÍVEL3",
	合格条件:"Depósito mínimo de 8$ por pessoa",
	随时可退:"não trancado",
	登录密码错误:"Senha de login errada",
	充值奖金:"Depósito USD≥50USD, bônus de 3%",
	充值奖金1:"Depósito IDR≥50USD, bônus de 2%",
	充值奖金2:"Depósito THB≥50USD, bônus de 5%",
	活动已过期:"O evento expirou, aguardando o próximo evento",
	不能重复发送短信:"Você não pode enviar mensagens de texto repetidas em 10 minutos. É recomendado que você use EMAIL CAPTCHA para se registrar diretamente.",
	限购说明1:"Olá, cada usuário do pacote de estratégia quantitativa de negociação de robôs NO.0 só pode comprá-lo uma vez.",
	修改钱包地址提示:"O endereço de retirada do USD não pode ser modificado após ser vinculado. Se precisar de ajuda, entre em contato com o atendimento ao cliente.",
	修改银行提示:"A conta bancária de retirada não pode ser modificada depois de vinculada. Se precisar de ajuda, entre em contato com o atendimento ao cliente.",
	注册推送消息1:"Bem-vindo ao INGSAI. Após novos usuários iniciarem um nó de mineração POS, eles receberão renda de mineração POS a cada 5 minutos. A receita da comissão da equipe será automaticamente lançada no saldo da conta por volta das 00h00 por dia.",
	限购说明:"Olá, o número máximo de compras para este tipo de pacote de robô quantitativo é de 10 vezes, é recomendável adquirir outros tipos de pacotes de negociação quantitativos.",
	注册协议:"Ao clicar em (Cadastrar conta), você confirma que tem mais de 18 anos. Você está ciente de que a Plus500AI pode usar seu endereço de e-mail ou outros dados pessoais para contatá-lo eletronicamente para fornecer informações relevantes sobre seus produtos/serviços. Também poderemos notificá-lo sobre eventos de mercado para ajudá-lo a aproveitar ao máximo sua experiência de negociação. Você pode gerenciar e modificar as preferências de notificação nas guias (Configurações de notificação) e (Configurações de privacidade) na Plataforma. Você pode cancelar a assinatura dessas comunicações a qualquer momento. Para obter mais informações, consulte nosso (Acordo de Privacidade)",
	公司说明1:"Plus500AI é uma marca registrada da Plus500 Ltd. Plus500 Ltd opera através das seguintes subsidiárias:",
	公司说明2:"Plus500SEY Ltd é autorizada e regulamentada pela Autoridade de Serviços Financeiros das Seychelles (licença nº SD039).",
	公司说明3:"Plus500SEY Ltd é o emissor e vendedor dos produtos financeiros descritos ou disponíveis neste site.",
	公司说明4:"Plus500UK Ltd é autorizada e regulamentada pela Autoridade de Conduta Financeira. Número de registro da Autoridade de Conduta Financeira: 509909. CFDs de criptomoeda não estão disponíveis para usuários de varejo.",
	公司说明5:"Plus500CY Ltd é autorizada e regulamentada pela Comissão de Valores Mobiliários de Chipre (código de autorização: 250/14). Os CFDs de criptomoeda não estão disponíveis para investidores de varejo do Reino Unido.",
	公司说明6:"Plus500AU Pty Ltd detém as seguintes licenças: AFSL (Nº 417727) emitido pela ASIC, FSP (Nº 486026) emitido pela FMA da Nova Zelândia e Provedor de Serviços Financeiros Autorizado (Nº 47546) emitido pela FSCA da África do Sul.",
	公司说明7:"Plus500EE AS ​​​​é autorizado e regulamentado pela Autoridade de Supervisão Financeira da Estônia (Licença nº 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) possui uma licença de serviços de mercado de capitais emitida pela Autoridade Monetária de Singapura, que lhe permite negociar produtos do mercado de capitais (número de licença CMS100648).",
	公司说明9:"Plus500AE Ltd é autorizada e regulamentada pela Autoridade de Serviços Financeiros de Dubai (F005651).",
    加入我们的社群:"Junte-se a nossa comunidade",
	PLUS500集团:"GRUPO PLUS500",
	受监管的操作:"OPERAÇÕES REGULADAS",
	健全的全球监管框架:"Quadro regulatório global robusto",
	治理和社会责任:"GOVERNANÇA E RESPONSABILIDADE SOCIAL",
	底部说明:"A Plus500 continua empenhada em operar de forma responsável e sustentável em todos os aspectos do seu negócio e acredita que esta abordagem é tanto o seu dever como uma parte essencial de uma gestão eficaz. A Plus500 está comprometida com uma série de iniciativas ESG para criar valor tangível para o nosso pessoal, clientes, comunidades locais e instituições de caridade e para os nossos acionistas.",
	投资者联系方式:"CONTATOS COM INVESTIDORES",
	投资者关系主管:"Chefe de Relações com Investidores",
	AI智慧型量化交易:"Negociação quantitativa inteligente de IA",
	订单执行快速可靠:"A execução de pedidos é rápida e confiável",
	全天候自动分析行情:"Análise automática de mercado 24 horas por dia",
	交易受监管低风险:"A negociação é regulamentada e de baixo risco",
	稳定策略高效收益:"Estratégia estável e retornos eficientes",
	取款快速且安全:"Retire dinheiro de forma rápida e segura",
	游戏说明:"O saldo mínimo necessário é de 10 USD para entrar no jogo e ganhar dinheiro",
	
	货币:"EUR",
	能量值:"Valor energético",
	财富值:"valor da riqueza",
	佣金:"comissão",
	累计工资:"Salário acumulado",
	工资:"salário",
	租赁:"alugar",
	去租赁:"Ir para aluguel",
	
	当前速率:"taxa atual",
	当前工资:"salário atual",
	预计今日收益:"Ganhos estimados hoje",
	累计佣金:"Comissão acumulada",
	邀请好友注册领取工资:'Convide amigos para se cadastrar e receber salário',
	月:'lua',
	符号:'pt',
	转盘抽奖:'Lotaria de roleta',
	剩余抽奖次数:'Número de sorteios restantes',
	关闭:'encerramento',
	恭喜您获得:'Parabéns por conseguir',
	很遗憾没有中奖:'Pena não ter ganho',
	游戏规则:'regras do jogo',
	租赁机器人后会获得抽奖次数:'Depois de alugar o robô, receberá o número de sorteios de lotaria',
	中奖金额会存入余额:'O valor do prémio será creditado no seu saldo',
	中奖记录请到收入记录中查看:'Por favor, verifique o registo de rendimentos para registos vencedores.',
	
	可购余额:'Saldo disponível para compra',
	可提现余额:'Saldo disponível para saque',
	总余额:'Saldo total',
	去划转:'Ir para a transferência',
	余额不足:'Saldo insuficiente',
	小于最低划转金额:'Menor que o valor mínimo de transferência',
	余额划转:'Transferência de saldo',
	可划转余额:'Saldo transferível',
	划转金额:'Valor da transferência',
	请输入划转金额:'Por favor, insira o valor da transferência',
	确认划转:'Confirmar transferência'
}
