<template>
  <div>
	 
    <layout>
		 
      <template #left>
        <div class="flexbox a-center title">
          <img class="smile-icon" src="@/assets/img/about.png" alt="" />
          <div class="ml5" style="align-self: flex-end">
            <span class="f32"></span>
            <span class="f22 ml5"></span>
          </div>
        </div>
      </template>
	  
      <template #title></template>
	   
      <template #right>
		  <img
		    class="msg-icon" style="width: 28px;height: 28px; position: absolute; left: -25px;"
		    src="@/assets/lang1.png"
		    alt=""
		    
		   @click="changlang()"
		  />
				<!-- <img
				  class="msg-icon" style="width: 28px;height: 28px; position: absolute; left: -20px;"
				  src="@/assets/app.png"
				  alt=""
				  
				 @click="downapp()"
				/> -->
        <img
          class="msg-icon"
          :src="icon.msg"
          alt=""
          @click="$router.push('/leaveMessage')"
        />
				
        <van-badge :content="info.msgcount" color="red" />
      </template>
	 
      <template #content>
		  
        <div class="content">
          <div class="notice flexbox ml32 mr32 a-center mt32">
            <img class="tz-icon ml32" :src="icon.tz" alt="" />
            
			<marquee behavior="down" scrolldelay="100" class="ml32 bcolor" style="color: #fff;">
			{{$t('恭喜新用户')}}{{str}}  |
			{{$t('恭喜新用户')}}{{str2}}  |  
			{{$t('恭喜新用户')}}{{str3}}  |
			</marquee>
          </div>
          <div class="line mt24 ml32 mr32 mb14"></div>
          <div class="mt20" @click="qianyuebuy()">
            <van-swipe class="banner ml32 mr32" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in bannerList" :key="index">
                <img class="img-banner" :src="item" alt="" />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="flex_row_space-between about mb20 mt30">
            <div class="ml32 flexbox a-center"></div>
          </div>
		  <div
		    class="robot ml14 mr14 flex_row_space-between"
		    @click="handleTo('/serveWay')"
		  >
		    <div class="flexbox a-center">
		      <img
		        class="robot-img ml34"
				style="width:64px;height:100%"
		        :src="require('@/assets/img/depo.webp')"
		        alt=""
		      />
		      <div class="ml34">
		        <div class="f32" style="font-weight:bold;">{{ $t('快捷充币') }}</div>
		        <div class="ccolor mt10">{{ $t('快速买USD') }}</div>
		      </div>
		    </div>
		    <div>
		      <img
		        class="jiantou"
		        :src="require('@/assets/icon/jiantou.png')"
		        alt=""
		      />
		    </div>
		  </div>
          <div class="mt22 nav-bg ml14 mr14">
            <div class="nav flexbox" style="color:#0c2780; font-weight:bold;">
              <div
                class="nav-item flexbox_column a-center"
                v-for="(item, i) in navList"
                :key="i"
                @click="handleTo(item.url)"
              >
                <img class="img-icon" :src="item.img" alt="" />
                <div class="mt5">{{ item.title }}</div>
              </div>
            </div>
			
            <div class="line mt24 ml32 mr32 mb14"></div>

			 <!-- <div class="ml14 mr14 f" style="font-size: 14px;color: red;" @click="changeL">临时测试按钮点击切换语言</div> -->
            <div class="earnings flex_row_space-between mt34">
              <div class="ml32 flexbox a-center" style="font-weight:bold;">
                <span class="f46">{{ (geren.geren_shouyi *  1).toFixed(2) }} USD</span>
                <span class="f24 ml5">{{ $t('今日收益') }}</span>
              </div>
              <!--<div class="mr32 f46">
                ≈<span style="font-size: 10px;">Mex$</span>{{ (geren.geren_amount * info.rate).toFixed(2) }}
              </div>-->
            </div>
            <div class="line ml32 mr32 mt26"></div>
            <div class="ml32 mr32 mt24 market flexbox">

            </div>
          </div>
		  <br>
		
          <div class="laste mt20 ml14 mr14">
            <div class="ml32 mr32 flex_row_space-between">
              <div class="f30" style="font-weight:bold;">{{ $t('购买教程') }}</div>
              <!-- <div @click="handleRobot">
                <span class="ccolor mr14">{{ $t('更多') }}</span>
                <img
                  class="jiantou"
                  :src="require('@/assets/icon/more.png')"
                  alt=""
                />
              </div> -->
            </div>
            <div class="mt30">
              <van-swipe :loop="false" :width="305" :show-indicators="false">
                <van-swipe-item v-for="item in aituring" :key="item.name">
                  <div class="flex_row_space-between">
                    <div class="flexbox a-center">
                      <img
                        class="robot-w"
                        :src="require('@/assets/img/ai.gif')"
                        alt=""
                      />
                      <div class="f30 ml20" style="color: #fff;font-weight:bold;">
                      {{ item.name }} | {{ $t('机器人性能') }}
                      </div>
                    </div>
                    
                  </div>
                  <div class="flexbox j-center mt20">
                    <div>
                      <div class="f18" style="color:#fff;font-size: 35px;font-weight:bold;">
                        {{ item.price }}  <span style="font-size: 25px;">USD</span>
<!--{{ (item.price * info.rate).toFixed(2) }}-->
                      </div>
                      <div class="mt5" style="color:#fff;font-size: 12px;text-align:center;">{{ $t('机器人价格') }}</div>
                    </div>
                    <div style="width: 50px"></div>
                    <div>
                     <!--<div class="f18" style="font-size: 15px;font-weight:bold;">
                        {{ item.dan_price }} USD <span style="font-size: 20px;"></span><!--{{
                          (item.dan_price * info.rate).toFixed(2)
                        }}-->
                      </div>
                      <!--<div class="mt5">{{ $t('激活金额') }}</div>
                    </div>-->
                  </div>
                  <div class="buy flex_row_space-between" style="font-weight:bold;">
                    <div class="ml32">
                      <span class="ccolor" style="color:#fff;">{{ $t('有效期') }} :</span>
					  <span class="ccolor" style="color:#fff;">&nbsp;&nbsp;  8:00 - 18:00</span>
                      <!-- <span class="ccolor" style="color:#fff;">&nbsp;&nbsp;  {{ item.gq_day }} {{ $t('天') }}</span><br>
                      <span class="ccolor" style="color:#fff;">{{ $t('日收益') }} :</span>
                      <span class="ccolor" style="color:#fff;">&nbsp;&nbsp;  {{ (item.earnings)}} USD</span>
					  <br>
					  <span class="ccolor" style="color:#fff;"> {{ $t('积分') }} :&nbsp;&nbsp;+{{item.zs_jyzhi}} </span><br> -->
                    </div>
                    <div class="btn mr32 bcolor" @click="handleRobot2(item, 1)">
                      {{ $t('租赁') }}
                    </div>
                  </div>
                </van-swipe-item>
                <van-swipe-item style="display: none"></van-swipe-item>
              </van-swipe>
            </div>
            <div class="mt30 hide partner">
              <p class="ml32 f30" style="text-align:center;font-weight:bold;">{{ $t('PLUS500集团') }}</p>
			  <p class="ml32 f30" style="text-align:center;">{{ $t('受监管的操作') }}</p>
			  <p class="ml32 f30" style="text-align:center;color:#000000;font-size:13px;">{{ $t('健全的全球监管框架') }}</p>
              <div class="mt20 ml32 mr32 flexbox flex-wrap">
                <div
                  class="partner-item flexbox center"
                  v-for="(item, i) in partnerList"
                  :key="i"
                  @click="handleUrl(item.url)"
                >
                  <img class="imgIcon" :src="item.icon" alt="" />
                </div>
				<div class="center" >
				<p  class="center" style="background:white;font-size:22px;font-weight:bold;">{{ $t('治理和社会责任') }}</p>
				<img class="" src="../../assets/b1.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b2.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b3.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b4.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b5.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b6.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/>&nbsp;&nbsp;
				<img class="" src="../../assets/b7.webp" style="width:142.7px;height: 169.2px;border-radius: 5px;"/><br>
				<p  class="center" style="background:white;font-size:13px;color:#555555;">{{ $t('底部说明') }}</p>
				<img class="" src="../../assets/mobile1.webp" style="width:100%;border-radius: 5px;"/><br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp;  {{ $t('AI智慧型量化交易') }}<br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp;  {{ $t('订单执行快速可靠') }}<br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp;  {{ $t('全天候自动分析行情') }}<br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp;  {{ $t('交易受监管低风险') }}<br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp;  {{ $t('稳定策略高效收益') }}<br><br>
				<img class="" src="../../assets/add0.png" style="width:15px;height:15px;font-size:40px;"/>&nbsp;&nbsp; {{ $t('取款快速且安全') }}<br><br>
				<img class="" src="../../assets/iPhone.png" style="width:100%;"/><br>
				<!-- <p  class="center" style="background:white;font-size:20px;color:#555555;">{{ $t('投资者联系方式') }}</p>
				<p  class="center" style="background:white;font-size:15px;font-weight:bold;color:#000000;">Carlo Panaccione</p>
				<p  class="center" style="background:white;font-size:13px;color:#555555;">{{ $t('投资者关系主管') }}</p>
				
				<p  class="center" style="background:white;font-size:13px;font-weight:bold;color:#2E86FE;">EMAIL:PLUS500AI.COM@GMAIL.COM</p> -->
				</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
   
    <ROBOT />
	
	<popup center ref="popup" >
		
		
	  <div class="poptip">
	    <img class="imgs" src="../../assets/img/vipxuzhi.png" alt="" />
	    <div class="ct">
	      <div
	        style="
	          color: #ffffff;
	          position: absolute;
	          top: 0.4rem;
	          right: 0.5rem;
	        "
	        @click="closemsg()"
	      >
	        X
	      </div>
	      <div class="text">{{ $t('弹窗内容') }}</div>
	    </div>
	  </div>
	</popup>
  </div>
</template>

<script>
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot,
  getjiqinum,
  getgameurl
} from '@/http/api/'
import popup from '@/components/popup/index.vue'
import pako from 'pako'
import ROBOT from '@/components/robot'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
import {
  Button,
  Badge,
  NoticeBar,
  Swipe,
  SwipeItem,
  Col,
  Row,
  Popup,
  Cell,
  Field
} from 'vant'
export default {
  components: {
    popup,
    ROBOT,
    [Badge.name]: Badge,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
   
    [Cell.name]: Cell,
    [Field.name]: Field
  },
  data() {
    return {
      icon: {
        smile: require('@/assets/icon/smile-icon.png'),
        msg: require('@/assets/icon/bell-icon.png'),
        tz: require('@/assets/icon/tz.png')
      },
      bannerList: [require('@/assets/img/plus7.jpg'),require('@/assets/img/plus10.png'),require('@/assets/img/plus5.jpg'),require('@/assets/img/plus6.jpg'),require('@/assets/img/plus2.png')],
      show: true,
      btc: '',
      fil: '',
      eth: '',
      token: '',
      info: [],
      iosdown: '',
      anddown: '',
      geren: '',
      aituring: [],
      HQlist: [],
      msgtz: 0,
      lingai: [],
	  str:"",
	  str2:"",
	  str3:""
    }
  },
  computed: {
    ...mapGetters(['partnerList']),
    navList() {
      return [
     
        {
          title: this.$i18n.t('navbar.提现'),
          img: require('@/assets/icon/wd.webp'),
          url: '/drawings'
        },
        {
          title: this.$i18n.t('navbar.帮助'),
          img: require('@/assets/icon/faq.webp'),
          url: '/helpCenter'
        },
        {
          title: this.$i18n.t('navbar.分享'),
          img: require('@/assets/icon/referrer.png'),
          url: '/joinUs'
        },
		
      // {
     //    title: this.$i18n.t('navbar.游戏'),
     //    img: require('@/assets/icon/ic_royal_monkey.png'),
     //    url: '/actionCenter'
     //  },
        {
          title: this.$i18n.t('navbar.推广'),
          img: require('@/assets/icon/ads.png'),
          url: '/promote'
        },
		{
		  title: this.$i18n.t('navbar.游戏'),
		  img: require('@/assets/icon/monkey.png'),
		  url: '/mygame'
		},
      ]
    }
  },
  created() {
    var token = localStorage.getItem('key')

    if (!token) {
      this.$router.replace('/login')
    }
    this.msgtz = localStorage.getItem('msg')
	
    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      // this.showdown = res.user.showdown
      this.info = res.user
      this.rate = res.user.rate
      this.iosdown = res.user.iosdown
      this.anddown = res.user.anddown
    })
    apiRobots({}).then((res) => {
      this.aituring = res.info
    })
    apimyrobots({ userid: token }).then((res) => {
      this.myaicount = res.info.length
    })
    apimyshouyi({ userid: token }).then((res) => {
      this.geren = res.info
      
      this.geren.geren_amount = res.info.geren_amount
     
    })
    // 0号机器人
    apizsrobots({ userid: token }).then((res) => {
      this.lingai = res.info
    })
  },
  mounted() {
    this.getHQ()
	if(this.msgtz == 1){
		this.$refs.popup.show()
	}
    this.homeNotice();
    console.log(this.HQlist)
  },
  methods: {
	  changlang(){
		  this.$router.push('/lang')
	  },
	  homeNotice(){
			var tell = /(\d{2})\d*(\d{4})/;
				let jiqiNum = Math.floor(Math.random()*5+1);
				jiqiNum = this.getjiqinum(jiqiNum);
				let telNum = getMoble();
				telNum = telNum.replace(tell, '4****$2')
				
				this.str = telNum+this.$t('升级')+jiqiNum;
				
				  jiqiNum = Math.floor(Math.random()*5+1);
				  jiqiNum = this.getjiqinum(jiqiNum);
				  telNum = getMoble();
				  telNum = telNum.replace(tell, '4****$2')
				this.str2 = telNum+this.$t("升级")+jiqiNum;
				
				 jiqiNum = Math.floor(Math.random()*5+1);
				 jiqiNum = this.getjiqinum(jiqiNum);
				 telNum = getMoble();
				 telNum = telNum.replace(tell, '4****$2')
				this.str3 = telNum+this.$t("升级")+jiqiNum;
				
				this.str3 = this.str+this.str3
	  },
	  // 转换机器人
	  	getjiqinum(num){
	  			return 'Robot'
	  			if(num==0){
	  				return this.$t('No.0');
	  			}else if(num == 1){
	  				return this.$t('No.1');
	  			}else if(num == 2){
	  				return this.$t('No.2');
	  			}else if(num == 3){
	  				return this.$t('No.3');
	  			}else if(num == 4){
	  				return this.$t('No.4');
	  			}else if(num == 5){
	  				return this.$t('No.5');
	  			}
	  	},
    qianyuebuy() {
      this.$router.push({
        name: 'myRobot',
        query: {
          info: this.lingai,
          type: 100
        }
      })
    },
    closemsg() {
      this.msgtz = 0;
	  this.$refs.popup.hide()
      window.localStorage.removeItem('msg')
    },
    about() {
      window.location.href = 'https://plus500.com/'
    },
    getHQ() {},
    sendSocket() {},
    open: function () {},
    getMessage: function (event) {},

    send: function () {},
    downapp() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        window.location.href = "https://www.plus500ai.com/app.mobileconfig";
      } else {
        window.location.href = "https://www.plus500ai.com/app.apk";
      }
    },
    handleUrl(url) {
      window.location.href = url
    },
    // 切换语言
    changeL() {
      this.$i18n.locale = 'en'
    },
    handleTo(url) {
      console.log(url)
      if(url == "/actionCenter"){
        console.log("进入游戏")
        var token = localStorage.getItem('key')

	
     this.token = token

      getgameurl({
         userid: token
      }).then((res) => {
          
            console.log(res)
          if(res.status==1){
            window.location.href = res.data;
          }else{
            Toast.fail(this.$t(res.info));
          }
        })
      }else{
        this.$router.push(url)
      }
      
    },
    handleRobot() {
      this.$router.push('/robot')
    },
    // 购买
    handleRobot2(info, type) {
      this.$router.push({
        name: 'robot',
        query: {
          info: info,
          type: type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.smile-icon {
  width: 302px;
  height: 80px;
  image-rendering: pixelated;
}
.title {
  line-height: 80px;
}
.msg-icon {
  width: 26px;
  height: 38px;
}
.content {
  .notice {
    height: 58px;
    background: #2e86fe;
    border-radius: 10px;
	 color: #fff;
    .tz-icon {
      width: 19px;
      height: 28px;
    }
  }
  .banner {
    height: 340px;
    .img-banner {
      width: 100%;
      height: 100%;
	  border-radius: 10px;
    }
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      /* height: 100%; */
      text-align: center;
    }
  }
  .nav-bg {
    margin-top: 20px;
    background-color: #ffffff;
    padding-top: 50px;
    border-radius: 10px;
   
	
    .nav {
      &-item {
        .img-icon {
          width: 120px;
          height: 120px;
        }
        width: 25%;
      }
    }
  }
  .about {
    .add0 {
      width: 32px;
      height: 34px;
      
    }
    .jiantou-w {
      width: 28px;
    }
    .about {
      width: 252px;
      height: 100px;
    }
  }
  .robot {
    height: 128px;
    background-color: #ffffff;
    border-radius: 10px;
    .robot-img {
      width: 100px;
      height: 120px;
    }
    .jiantou {
      width: 17px;
      margin-right: 40px;
    }
  }
  .market {
    .market-item {
      width: 33.33%;
      padding: 0 10px;
      .img-updown {
        width: 162px;
        height: 55px;
        margin-top: 15px;
      }
      .up {
        color: #ff3a53;
      }
      .down {
        color: #32ba8d;
      }
    }
  }
  .laste {
    background-color: #ffffff;
	border-radius: 10px;
    padding: 20px 0;
    .van-swipe {
      /* height: 337px; */
      .van-swipe-item {
        padding: 20px;
        /* width: 405px; */
        /* height: 337px; */
        margin-left: 32px;
        background: url('../../assets/aitrade.jpg') no-repeat center center;
		background-size: 100% 100%;
		//background-size: cover;
		//background-repeat: no-repeat;
		//background: linear-gradient(1deg,#ffcc8f,#ffedd8);
        box-shadow: 0px 19px 26px 1px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
       // opacity: 1;
        .robot-w {
          width: 100px;
          height: 120px;
        }
        .buy {
          background: hsla(0, 0%, 100%, .2);
          height: 100px;
          border-radius: 10px;
          margin-top: 20px;
          .btn {
            height: 60px;
            padding: 0 30px;
			color:#fff;
            background: hsla(0, 0%, 100%, .3);
			box-shadow: 0px 8px 30px 1px rgba(243, 204, 46, 0.30000001192092896);
            border-radius: 10px;
            line-height: 60px;
          }
        }
      }
    }
    .jiantou {
      width: 16px;
    }
  }
  .partner {
    background-color: #ffffff;
	border-radius: 10px;
    .partner-item {
      width: 32%;
      margin-bottom: 20px;
      background-color: #ffffff;
	  box-shadow: 0px 0px 7px 0px rgba(1, 1, 1, 0.35);
      height: 58px;
      border-radius: 10px;
      margin-left: 3px;
      .imgIcon {
        width: 140px;
        height: 40px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  // .bg {
  //   height: 20px;
  //   background-size: cover;
  //   background: url('../../assets/img/会员须知.png') no-repeat;
  // }
  .poptip {
    margin-left: 32px;
    margin-right: 32px;
    // height: 442px;
    background: #ffffff;
    position: relative;
    // padding: 20px 30px;
    border-radius: 25px;
    .ct {
      padding: 0px 30px 20px;
      .text {
        color: #0c2780;
        line-height: 50px;
      }
    }
    .imgs {
      width: 100%;
      height: 250px;
      // position: absolute;
      // left: 0;
      // top: 0;
      // transform: translate(-0%, -50%);
    }
  }
}
</style>
