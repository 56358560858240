export default {
  tabbar: {
    首页: 'Casa',
    市场: 'Mercado',
    任务: 'Tarea',
    团队: 'Equipo',
    我的: 'Mi'
  },
  navbar: {
    充值: 'Depósito',
    提现: 'Retirar',
    帮助: 'FAQ',
    分享: 'Invitar',
	推广: 'Ads Ingresos',
    活动: 'Premio',
    游戏: 'Juego',
  },	 
  payment: {
    付款方式详情: 'Forma de pago',
    银行卡: 'Tarjeta de banco',
    钱包地址: 'Dirección de la billetera',
    交易密码: 'Contraseña de transacción',
    保存: 'Configurar'
  },
  market: {
    市场: 'Mercado',
    名称: 'Nombre',
    价格: 'Precio',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: 'Yo veo'
  },
  邀请好友注册领取佣金:"Invita a amigos a registrarse y recibir comisiones.",
  领取:"Recibir",
  未满足条件:"Condición no cumplida",
  总收入:"Ingresos totales",
  投资建议:"※Llevar a cabo un plan de inversión de interés compuesto para obtener rendimientos más sustanciales",
  语言切换: 'Cambio de idioma',
  POS区块节点挖矿: 'Detalles comerciales cuantitativos de Ai',
  节点类型: 'Robot\nTipo',
  加入价格: 'Comprar\nPrecio',
  质押资金: 'POS\nFondos',
  质押周期: 'Período\n(días)',
  每日收入: 'Ingresos\ndiarios',
  累计积分: 'Puntos\nAcumulados',
  代理等级: 'Agente\nNivel',
  下级折扣比: 'Subordinado\nDescuento',
  代理折扣比: 'Agente\nDescuento',
  下属等级: 'Subordinado\nNivel',
  代理佣金比率: 'Agente\nComisión\nTarifa',
  下属每日收入: 'Subordinado\nIngresos\ndiarios',
  代理佣金: 'Agente\nComisión',
  引用对象: 'Referencia\nobjeto',
  积分等级折扣规则: 'Reglas de descuento por nivel de puntos',
  积分等级折扣规则介绍: 'Los usuarios que participen con éxito en el comercio cuantitativo de Ai recibirán los puntos de cuenta correspondientes, y los usuarios superiores de sus agentes también podrán aumentar los puntos correspondientes (aplicable al 15% de los subordinados directos). Cuando los puntos totales de la cuenta cumplan con las condiciones correspondientes, el nivel del agente La cuenta se mejorará. Las cuentas de agentes en diferentes niveles pueden obtener diferentes proporciones de descuentos en los precios. Los detalles de los descuentos de puntos relevantes son los siguientes:',
  积分等级折扣规则介绍2: 'Los usuarios con diferentes relaciones (directas) recibirán descuentos y descuentos a nivel de agente en función de la participación de los subordinados en las transacciones cuantitativas de Ai. Otros altos ejecutivos también recibirán niveles de descuento en proporción a sus propios agentes. Descuentos de precios por participar en transacciones cuantitativas de Ai: los usuarios con los niveles de agente correspondientes recibirán los derechos preferenciales correspondientes a nivel de agente al participar en transacciones cuantitativas de Ai.',
  团队: 'Equipo',
  代理佣金制度: 'Sistema de comisiones de agencia',
  代理佣金说明详情: 'Proporción de agentes LV1 obtenidos por A: 8 %, proporción de agentes LV2 obtenidos por A: 3 %, proporción de agentes LV3 obtenidos por A: 2 %. Ejemplo: La ganancia diaria de B, C y D es: 100 USD, y la recompensa del agente LV1 obtenida por A: 8%*100=8 USD. La recompensa del agente LV2 obtenida por A: 3%*100=3 USD. La recompensa del agente LV3 obtenida por A: 2%*100=2 USD. El ingreso total por comisiones es: 8+3+2=13 USD.',
  代理结构图示: 'Diagrama de estructura de proxy',
  代理结构图示说明: 'A lo representa, A invita a B (el subordinado de A es B), B invita a C (el subordinado de B es C) y C invita a D (el subordinado de C es D). A puede tener múltiples subordinados (B1, B2, B3, B4, B5, B6, etc.), B (B1, B2, B3) también puede tener múltiples subordinados (C1, c2, c3, c4, c5, c6, etc. ) CD...',
  高级代理佣金说明: 'Instrucciones para la Comisión de Agente Senior',
  高级代理佣金说明详情: 'A lo representa, A invita a B (el subordinado de A es B), B invita a C (el subordinado de B es C) y C invita a D (el subordinado de C es D). El agente superior obtendrá diferentes ingresos por comisiones de agencia (%) de acuerdo con la proporción del monto total de la minería de nodos involucrada en su cuenta propia y cuentas subordinadas. A compara los ingresos diarios de los miembros subordinados para obtener la proporción correspondiente de las recompensas de la agencia. Los nodos participantes deben estar en funcionamiento. Los ingresos de la agencia son proporcionados por INGSAI MINING para todos los gastos financieros y no afectarán los ingresos diarios de los miembros subordinados normales.',
  高级代理佣金说明详情2: 'Ejemplo: La cantidad total de minería de los agentes principales (A)>30 % de la cantidad total de minería de los miembros inferiores (B/C/D) (Ejemplo: A>30 % de B/C/D)',
  积分: 'Puntos',
  规则: 'Regla',
  会员级别: 'Nivel de afiliación',
  经验: 'Puntos totales',
  代理信息: 'Información de miembro',
  更多: 'Más',
  团队奖励: 'Comisión de equipo',
  昨天: 'Ayer',
  本星期: 'Esta semana',
  全部: 'Todos',
  团队收入记录: 'Ingresos del equipo',
  机器人说明: "Los usuarios de Plus500Ai pueden comprar múltiples paquetes comerciales cuantitativos al mismo tiempo, y el robot N°1 solo puede comprar 10 veces por cuenta. Recuerda: No olvides hacer clic para activar la estrategia después de comprar el robot cuantitativo, de lo contrario no habrá ingresos. El tiempo de validez del robot cuantitativo se calcula en función del momento de la compra. Active la estrategia comercial cuantitativa a tiempo. Si el robot cuantitativo no se ha activado después de su vencimiento, perderá el costo de comprar el paquete de estrategia comercial cuantitativa. ¡Todos los usuarios que participan en el comercio cuantitativo Plus500Ai deben leer atentamente este reglamento! !",
  会员体验: 'Experiencia de miembro',
  机器人采购: 'Compra de Robot',
  机器人启动押金: 'Fondos de POS de nodo',
  机器人礼物: 'Regalo de nodo',
  机器人订单: 'Orden de los nodos',
  一次性总和: 'Espectáculo',
  总计: 'Total',
  
  被激活: 'YA POS',
  工作中: 'ACTIVADO',
  暂停: 'Suspender POS',
  结束: 'FINALIZAR',
  刷新: 'Refrescar',
  来源: 'De',
  价格: 'Precio del paquete robot',
  启动押金: 'Monto de POS',
  剩余时间: 'Tiempo restante',
  激活时间: 'Hora de activación',
  号: 'NÚM',
  我的资产: 'Mis activos',
  退出: 'Cerrar sesión',
  邀请码: 'Código de invitación',
  我的钱包余额: 'El saldo de mi billetera',
  我的机器人仓库: 'Mi Lista De Robots',
  收益面板: 'Panel de ganancias',
  去提款: 'Solicitar retiro',
  昨天代理返利: 'Ingresos del agente ayer',
  累计代理返利: 'Ingresos acumulados de la agencia',
  今天机器人收益: 'Beneficio comercial de hoy',
  累计机器人收益: 'Ingresos comerciales acumulados',
  累计机器人回扣: 'Comisión comercial acumulada',
  累计总回报: 'Ingreso total acumulada',
  自动订单机器人数量: 'Robot de comercio cuantitativo activado',
  我的付款方式: 'Mi método de pago',
  个人收入记录: 'Ingreso personal',
  团队收入记录: 'Ingresos del equipo',
  记录详情:'Detalles del récord',
  充值记录: 'Recargar registros',
  我的团队成员: 'Los miembros de mi equipo',
  活动中心: 'Reglas Introducción',
  修改登录密码: 'Establecer contraseña de acceso',
  交易密码管理: 'Establecer contraseña de transacción',
  机器人: 'Estrategia comercial de IA',
  机器人性能: 'Panel De Robots',
  机器人价格: 'Precio de estrategia comercial',
  有效期: 'Período de validez comercial',
  钱包地址: 'Dirección de la billetera',
  天: 'día',
  去购买: 'Compra',
  买个机器人: 'Comprar robot comercial cuántico',
  我的钱包余额: 'Mi saldo de cuenta',
  预计日收益: 'Ingreso diario estimado',
  启动保证金: 'Depósito inicial',
  请输入购买数量: 'Ingrese la cantidad de compra',
  个机器人: ' Robot comercial cuántico',
  机器人购买后需要激活才能工作: 'Después de comprar un nodo, debe prometer la cantidad correspondiente para comenzar a beneficiarse.',
  机器人激活需要从余额中扣除相应的启动押金:
    'La minería de compromiso de nodo debe deducir el monto de compromiso correspondiente del saldo de la cuenta',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Al suspender la minería de compromiso de nodo, el monto prometido se puede devolver de inmediato',
  购买: 'Compra',
  点击去了解我们: 'Comercio Cuantitativo',
  购买机器人: 'Compra un robot',
  快速入口: 'Entrada rápida',
  '日/个人受益': 'Día/beneficio personal',
  购买教程: 'Lista de robots comerciales de IA',
  激活金额: 'TARIFA DE POS',
  订单数量: 'Cantidad de pedido',
  我们的合作伙伴: 'Nuestros socios de criptomonedas',
  提款: 'Retiro',
  提款金额: 'Monto del retiro',
  你还没有添加银行卡: 'No has añadido una tarjeta bancaria',
  提现说明: 'Instrucciones de retiro',
  说明1: '1.Puede seleccionar con un clic o ingresar manualmente el monto de la recarga',
  说明2: '2.Se recomienda utilizar USD (TRC20), la tarifa de transferencia es la más baja',
  说明3: '3.El horario de atención al cliente es de Lunes a Viernes AM8 a PM6 Hora Centroamericana',
  说明4: 'Los usuarios que usan USD para recargar deben cargar la captura de pantalla correcta de la transferencia exitosa y el número TXID, y la cuenta se recibirá correctamente después de que la plataforma pase la revisión.',
  说明5: '4.La recarga mínima es de 8 USD, lo cual es conveniente para que el sistema revise y procese rápidamente la cuenta',
  说明6: '5.Si tiene alguna pregunta sobre la recarga, comuníquese con el servicio al cliente en línea a tiempo para obtener ayuda.',
  确认提款: 'Confirmar retiro ',
  请输入提现金额: 'Monto del retiro ',
  提现费:'Comisión de retiro',
  提现须知: 'Por favor, lea atentamente las reglas de retiro',
  TIPS1: '1:El tiempo de revisión de retiro diario es de lunes a viernes AM8 a PM6. Los retiros se pueden hacer los fines de semana y el tiempo de revisión de retiros es el lunes',
  TIPS2: '2:Sistema 7X24 horas, retirar dinero en cualquier momento, llegar a la cuenta dentro de las 24 horas a más tardar y llegar a la cuenta dentro de los 5 minutos como mínimo',
  TIPS3: '3:Horario de atención al cliente en línea: de lunes a viernes AM8 a PM6',
  TIPS4: '4:Debido al costo de la transferencia de USD, la plataforma cobra una tarifa de retiro cuando el usuario retira dinero',
  TIPS5: '5:El monto mínimo de retiro es de 10 USD',
  钱包余额: 'Saldo de la billetera',
  输入数量:"Introduce la cantidad",
  数量:"Cantidad",
  手机号码:"Número de teléfono",
  手机号码已存在:"El número de teléfono móvil ha sido registrado.",
  邮箱:"GMAIL",
  密码:'Contraseña',
  输入手机号码: 'Número de teléfono',
  请输入登录密码: 'Contraseña de inicio de sesión',
  登录: 'Iniciar sesión',
  输入账号: 'Ingrese el número de cuenta',
  输入密码:'Contraseña de inicio de sesión',
  点击注册: '¿No tienes una cuenta? ¡Crea una ahora!',
  忘记密码: 'Olvida la contraseña',
  重新登录: 'Iniciar sesión de nuevo',
  密码找回成功: 'Contraseña recuperada con éxito',
  找回成功请重新登录: 'Recuperar con éxito, por favor inicie sesión de nuevo',
  确认: 'Confirmar',
  发送验证码: 'código de verificación',
  再次输入密码: 'Ingrese de nuevo la contraseña',
  输入验证码: 'Ingrese CAPTCHA',
  输入手机验证码:"Ingrese la OTP móvil",
  输入手机号: 'Introduce el número de móvil',
  快速注册: 'Registro rápido',
  注册: 'Registro',
  立即登录: 'Ya tiene una cuenta, haga clic para iniciar sesión',
  请输入邮箱: 'Dirección de gmail',
  输入用户名: 'Ingrese su nombre de usuario',
  请输入邀请码: 'Introduce el código de invitación',
  返回: 'Regreso',
  密码找回失败: 'Error al recuperar la contraseña',
  输入的信息有误: 'La información ingresada es incorrecta',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Número de teléfono no puede estar en blanco",
	账号不存在:"Cuenta no existe",
	发送成功:"Enviado con éxito",
	今日收益:"(ingreso actual)",
	有效期:"Ciclo de POS",
	天:"día",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Hola, si cancela POS a mitad de camino, el sistema cobrará una tarifa de del 5%. Recordatorio especial: después de que se suspenda el nodo gratuito de la experiencia, no podrá volver a obtenerlo, ¡ni podrá obtener ninguna tarifa! !",
	我同意:"Acepto",
	用户账号ID:"ID de cuenta",
	取消:"Cancelar",
	日收益:"Ingreso diario",
	请输入手机号码:'Introduce el número de móvil',
	请输入购买数量:"Ingrese la cantidad de compra",
	数量不能为空:"La cantidad no puede estar vacía",
	加载中:"Sobreprima",
	唯一码:"Codificación de Robot",
	未激活:"INACTIVO",
	激活:"Iniciar Estrategia",
	购买时间:"Tiempo de compra",
	"钱包余额不足，激活该机器人失败":"Saldo de cuenta insuficiente, incapaz de llevar a cabo la minería de participación de nodos",

	运行时长:"Tiempo de corrida",
	签约购买:"Compra de saldo",
	系统赠送:"Regalo del sistema",
	状态:"Estado",
	正常:"Normal",
	我的机器人:"Mi robot comercial cuántico",
	一级会员:"NIVEL 1",
	二级会员:"NIVEL 2",
	三级会员:"NIVEL 3",
	人:"",
	充值客服:"Recargar servicio al cliente",
	充值:"Depósito",
	提现:"Retirar",
	提款密码:"Contraseña de retiro",
	设置交易密码:"Establecer contraseña de transacción",
	登录密码:"Contraseña de acceso",
	请输入交易密码:"Ingrese la contraseña de la transacción",
	再次请输入交易密码:"Confirmar contraseña de transacción",
	确认: 'Confirmar',
	手机号码不能为空:"El número de teléfono no puede estar en blanco",
	两次密码不一致:"Las dos contraseñas no coinciden",
	请输入验证码:"introducir el código de confirmación",
	操作成功:"Operación exitosa",
	"用户名或密码不正确,登录失败":"El nombre de usuario o la contraseña son incorrectos, el inicio de sesión falló",
	登录成功:"Inicio de sesión exitoso",
	充值说明:"Instrucciones de recarga",
	请先设置支付密码:"Por favor, establezca primero la contraseña de la transacción",
	复制成功:"Copiar con éxito",
	冻结机器人做单本金:"Congelar fondos cuantitativos",
	待审核:"Revisión pendiente",
	成功:"Éxito",
	失败:"Falla",
	审核中:"Bajo revisión",
	在线充值:"Recarga en línea",
	描叙:"Describir",
	POS节点挖矿LV0:"minería de nodos POS-LV0",
	POS节点挖矿LV1:"minería de nodos POS-LV1",
	POS节点挖矿LV2:"minería de nodos POS-LV2",
	POS节点挖矿LV3:"minería de nodos POS-LV3",
	POS节点挖矿LV4:"minería de nodos POS-LV4",
	POS节点挖矿LV5:"minería de nodos POS-LV5",
	银行卡提现:"Retiro de efectivo con tarjeta bancaria",
	USD提现:"Retiro de USD",
	三级代理:"Agente Nivel 3",
	一级代理:"Agente Nivel 1",
	二级代理:"Agente Nivel 2",
	一级:"Nivel 1",
	二级:"Nivel 2",
	三级:"Nivel 3",
	做单:"Minería de nodos",
	登录失败:"Falló el inicio de sesión",
	请勿重复操作:"No operar con frecuencia",
	邀请码不正确:"Código de invitación es incorrecto",
	团队返佣:"Comisión de equipo",
	购买机器人返佣:"Comisión de robots",
	本金返还:"Devolución de POS",
	佣金收入:"Ingresos por comisiones",
	时间:"Tiempo",
	机器人做单返还:"Devolución de POS",
	涨幅:"Cambio",
	市场:"Mercado",
	客服:"Servicio",
	验证码错误:"Error de código de verificación",
  付款方式:"Dirección de retiro de USD",
	我的留言:"Mi mensaje",
  暂无数据: 'Sin datos',
  银行卡:'Tarjeta de banco',
  修改: 'Modificar',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Asegúrese de que la dirección de su billetera sea correcta. Si ingresa una dirección incorrecta y no puede recibir el dinero, comuníquese con el servicio de atención al cliente en línea a tiempo para obtener ayuda.',
 连接借记卡:"vincular tarjeta de debito",
 银行名称:'Nombre del banco',
 姓名姓氏: 'Nombre',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*Recordatorio importante: la información de la cuenta bancaria debe ser verdadera y válida antes de poder retirar dinero.。',
 帮助中心:'Centro de asistencia',
 会员须知:'会员须知',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Yo veo',
 链接点击:'Enlace de registro de referencias',
 确定:'Confirmar',
 建立自己的团队邀请:'Construir mi equipo',
 此账号已冻结:"Esta cuenta ha sido congelada",
 手机号存已存在:"El número de teléfono móvil ya existe",
 注册账号已存在:"La cuenta registrada ya existe",
 请确定新密码:"Confirmar nueva contraseña",
 请再次输入登录密码:"Confirmar contraseña de acceso",
 密码长度不能少于6位:"La longitud de la contraseña no puede ser inferior a 6 caracteres.",
 加入:'Unirse',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "El nodo que vendió ha sido aceptado por la otra parte y los ingresos de la venta se han transferido a su cuenta, verifique。",
 	"你出售的机器人对方已拒绝接受":"El nodo que vendió ha sido rechazado por la otra parte",
 	向你赠送一台机器人:"Darte un nodo, por confirmar",
 	向你出售一台机器人:"Venderte un nodo, pendiente de confirmación",
 	出售价格不能超过机器人价格:"El precio de venta no puede exceder el precio de nudo",
 	银行卡已被绑定:"La tarjeta bancaria ha sido vinculada",
 	USD_TRC20提现:'Retiro de USD_TRC20',
 	USD_ERC20提现:'Retiro de USD_ERC20',
 	银行卡提现:'Retiro de efectivo con tarjeta bancaria',
 	邀请赠送:'Bono de invitación',
 	机器人返佣:'Comisión de robots',
 	升级:' compra exitosa ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "No puede vender nodos, comuníquese con el servicio al cliente para obtener más detalles.",
   交易编号: "Número de transacción",
   消息详情:"Detalles del mensaje",
   个人收益累计:"Ingresos personales acumulados",
   今日代理收益:"Ingresos de la agencia hoy",
   代理累计收益:"Ingresos acumulados de la agencia",
   截图保存推荐给朋友:"Comparta su enlace de registro de referencia e invite a amigos a unirse a la plataforma Plus500Ai para obtener recompensas de comisiones por ingresos del equipo. La comisión de ingresos para los miembros del equipo de tercer nivel es del 8% -3% -2%. La comisión del equipo se basa en los datos de ingresos diarios de los miembros de nivel inferior y se liquidará automáticamente y se ingresará en el saldo de su cuenta después de las 00:00 todos los días.",
   复制:"Copiar",
   充值提示1:"Consejo 1: La recarga mínima es de 10 USD, puede recargar, de lo contrario no se le acreditará",
   充值提示2:"Consejo 2: la recarga debe confirmarse en la cadena. Después de una recarga exitosa, se espera que llegue al saldo de la billetera en aproximadamente un minuto。",
   请输入正确的充值金额:"Cantidad de depósito ≥ 8 USD",
   推荐:"Recomendar",
   充值金额:"Monto de recarga",
   请上传付款截图:"Cargue una captura de pantalla del pago exitoso,después de que la auditoría del sistema sea exitosa, se acreditará automáticamente",
   交易号: 'Número hash de transacción',
   充值ID提示:"Pegue su número de serie Txid",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Asegúrese de que la dirección de la billetera sea correcta. Cualquier pérdida causada por no recibir dinero debido al llenado incorrecto de la dirección de la billetera correrá a cargo del usuario.",
	上传凭证:"Subir comprobante de pago",
	充值确认: "Enviar",
	不能为空:"No puede estar vacío",
  绑定钱包地址:'Vincular dirección de billetera',
	备注:"Comentario",
	请输入银行卡号:"Ingrese el número de cuenta bancaria",
	请输入你的名字:"introduzca su nombre",
	银行卡号:"Cuenta bancaria",
	添加银行卡:"Vincular cuenta bancaria",
	请选择银行:"Selecciona un banco",
	请输入钱包地址:"Por favor ingrese la dirección de la billetera",
	钱包地址:"Dirección de la billetera",
	"密码错误次数过多,请等待x秒后再试":"Demasiadas contraseñas incorrectas, espere 600 segundos y vuelva a intentarlo",
	"此账号已冻结,请联系客服":"Esta cuenta ha sido congelada, comuníquese con el servicio al cliente",
	手机号格式错误:"Número de teléfono mal formado",
	"取款须知":"Aviso de Retiro",
	"须知1":"1. Use USD-TRC20 para retiros en tiempo real dentro de las 24 horas (recomendado)",
	"须知2":"2. Retiro en sábado y domingo",
	"须知3":"*Los usuarios pueden retirar dinero los sábados y domingos",
	"须知4":"*Los retiros de fin de semana llegarán el lunes 10AM-8PM",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	请输入请款金额:"Ingrese el monto del retiro",
	已绑定:"Ha atado",
	去设置:"Ir a ajustes",
	注册成功:"Registración exitosa",
	汇率:"tipo de cambio",
	请输入正确的提现金额:"Ingrese el monto de retiro correcto",
	提款记录:"Historial de retiros",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"consejo 1: la dirección de la billetera vinculada debe ser la misma que la de la billetera de pago, de lo contrario no se acreditará。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"consejo 2: se espera que llegue aproximadamente un minuto después de que la recarga sea exitosa, verifique el saldo de la billetera.",
	请先绑定钱包地址:"Vincule primero la dirección de la billetera",
	输入邮箱:"Ingrese email",
	邮箱已存在:"El buzón ya existe",
	注册邮箱已存在:"El correo registrado ya existe",
	指定用户不存在:"El usuario especificado no existe",
	今日涨幅:"Cambio",
	恭喜新用户:"Felicidades nuevo usuario ",
	快捷充币:"Depósito",
	快速买USD:"Recarga de USD",
	在线支付:"Pago en línea",
	自动到账:"Entrada automática",
	线下支付:"Pagos fuera de línea",
	联系客服获取验证码:"Póngase en contacto con el servicio de atención al cliente para obtener el código de verificación",
	获取:"Obtener",
	退出成功:"Salir exitosamente",
	不能低于最小充值:"No puede ser inferior a la recarga mínima ",
	不能低于最小提现金额:"No puede ser inferior al monto mínimo de retiro ",
	最小提现:"Retiro mínimo ",
	设置成功:"Establecido con éxito",
	官方充币:"Depósito del sistema",
	银行卡充值:"Recarga bancaria",
	等待审核:"Esperando reseña",
	可提现金额不足:"Monto insuficiente disponible para retiro",
	未经授权:"No autorizado",
	交易密码不正确:"La contraseña de la transacción es incorrecta.",
	提现次数不足:"Tiempos de retiro insuficientes",
	官网:"página web oficial",
	账户USD不足:"El saldo de la cuenta USD es insuficiente",
	激活成功: "POS EXITOSA",
	操作成功: "Operación exitosa",
	修改成功:"Modificado con éxito",
	注册说明:"Mobile OTP y Email CAPTCHA solo necesitan elegir un método para verificar y podrá registrarse exitosamente",
	弹窗内容:
	"Bienvenido a la plataforma de comercio cuantitativo Plus500Ai. Cada usuario puede utilizar el bono de registro de 1 USD para comprar el paquete de estrategia de comercio de robot cuantitativo NO.0, que es válido por 1 día y ganar 0,5 USD gratis. Otros paquetes de estrategias comerciales cuantitativas requieren el uso de las tarifas correspondientes. Después de comprar el robot, los usuarios deben activar la estrategia comercial cuantitativa antes de que pueda comenzar a ejecutarse automáticamente. Cada estrategia comercial corresponde a un período de validez diferente. Si el usuario no hace clic para activar la estrategia después de comprar los puntos de robot correspondientes, no recibirá ningún ingreso comercial.",
	标题1:'1.Introducción a la plataforma Plus500',
	标题2:'2. Ventajas del comercio cuantitativo con IA',
	标题3:'3. ¿Por qué elegir Plus500Ai?',
	标题4:'4. Cómo unirse a Plus500Ai',
	标题5:'5.Perspectivas del mercado Plus500Ai',
	标题6:'6.Modelo de ingresos Plus500Ai',
	标题7:'7.Regulaciones de Depósito y Retiro',
	标题8:"Jugabilidad de batalla real",
	内容8:"Hay 8 salas en el juego Battle Royale en la plataforma Plu500Ai. Los jugadores pueden elegir cualquier sala entre las 8 salas para colocar monedas de oro. Después de que finalice la cuenta regresiva del sistema, aparecerá un asesino en el sistema de juego. El asesino ingresa aleatoriamente a una sala. y elimina a las personas en la sala. Otros jugadores pueden compartir las monedas de oro en esta sala y obtener ganancias. La jugabilidad del juego Battle Royale es relativamente corta, y el juego dura solo unos minutos, por lo que los jugadores pueden jugarlo en su tiempo libre. La cantidad de división = el número total de habitaciones asesinadas por el asesino en este período. Monto real de acciones = monto de acciones * 0,9 (excluyendo el índice de destrucción de 0,1). Proporción de participación = monto de participación real / monto total de la apuesta en otras salas. Monto ganador = proporción de participación * monto real de la apuesta. 1 moneda de oro = 1 USD y se requiere un mínimo de 10 USD para participar en el juego Battle Royale.",
	内容1:"La plataforma comercial Plus500 es proporcionada por Plus500SEY Ltd. En consecuencia, Plus500SEY Ltd es el emisor y vendedor de los productos financieros descritos u ofrecidos en este sitio web. Plus500SEY Ltd es una empresa con sede en Seychelles (n.º de empresa 8426415-1) con oficinas en Victoria. La empresa está autorizada y supervisada por la Autoridad de Servicios Financieros de Seychelles (FSA) con el número de licencia SD039 y ofrece contratos por diferencia (CFD) sobre varios productos básicos. La empresa es un proveedor de CFD en rápido crecimiento que actualmente ofrece una cartera de más de 2800 instrumentos financieros. Plus500SEY Ltd es una filial de Plus500 Ltd, que cotiza en el mercado principal de la Bolsa de Valores de Londres y tiene su sede en Haifa.",
	内容2:'La ventaja del comercio cuantitativo totalmente automatizado con IA es que utiliza herramientas racionales para realizar transacciones de acuerdo con la configuración del modelo. El robot no sufre cambios emocionales y puede superar la naturaleza humana, la codicia y el miedo humanos y muchas otras deficiencias y debilidades en el proceso de operación. Las operaciones manuales a menudo se ven afectadas por el alza y la caída del mercado y varios otros factores, y no pueden operar de acuerdo con el mejor modelo de ganancias durante mucho tiempo.',
	内容3:'Plus500Ai ha fortalecido gradualmente su posición como grupo fintech de múltiples activos al expandir sus productos principales a mercados nuevos y existentes, lanzar nuevos productos comerciales y financieros y profundizar su compromiso con los clientes. Proteja sus activos y proteja sus claves de cifrado con tecnología de seguridad líder en la industria. Plus500Ai también ofrece custodia global, lo que significa que estamos regulados, auditados y asegurados hasta $250 millones. Implemente su capital con el objetivo no sólo de mantener activos, sino también de ponerlos a trabajar. Plus500Ai le permite desplegar sus fondos de diversas formas, desde operaciones cuantitativas de Ai hasta servicios primarios clásicos, según su tolerancia al riesgo. Plataforma en línea para brindar más servicios a sus propios usuarios sin contratar ingenieros de blockchain adicionales.',
	内容4:"Desde el establecimiento de Plus500Ai, solo ha existido un sitio web oficial, WWW.PLUS500AI.COM. Todos los usuarios deben iniciar sesión en el sitio web oficial para descargar la APLICACIÓN, ya que otros sitios web no son de nuestra empresa. Para registrarse, debe enviar el número de teléfono móvil del usuario, la dirección de correo electrónico y el código de invitación del recomendador para registrarse rápidamente. Todas las comunicaciones con los clientes se realizan por escrito, ya sea a través de correo electrónico o chat online. Para comunicarse con Plus500 por correo electrónico, complete el formulario en la página Contáctenos (Formulario de solicitud). Después de enviar el formulario de solicitud, Plus500 enviará una respuesta por correo electrónico directamente a la dirección de correo electrónico que especificó en el formulario de solicitud. Si tiene alguna pregunta, comuníquese con el servicio de atención al cliente.",
	内容5:'Plus500Ai opera en todos los productos, dispositivos y países para brindar a los clientes oportunidades comerciales financieras confiables e intuitivas. Siempre hemos buscado la excelencia en nuestros productos y equipos. Con tecnología patentada avanzada y un enfoque centrado en el cliente, nuestros usuarios reciben una experiencia de servicio comercial de primera clase.',
	内容6:"Plus500Ai proporciona estrategias comerciales cuantitativas profesionales para el mercado de criptomonedas a través de un equipo de analistas de datos senior. Los usuarios que invierten en el robot comercial cuantitativo Ai pueden obtener ganancias comerciales cuantitativas confiando en las estrategias comerciales combinadas con el robot. El robot de comercio cuantitativo Ai analiza cíclicamente el tiempo de comercio óptimo basándose en la estrategia de comercio de mercado de Plus500Ai y obtiene ganancias comerciales cuantitativas a través del modelo de compra y venta de bajo nivel a precios altos. Plus500Ai proporciona a los usuarios inversores estrategias comerciales cuantitativas óptimas todos los días, y la plataforma extrae el 5% de los ingresos cuantitativos de los usuarios como fondos operativos de la plataforma.",
	内容7:'Plus500Ai actualmente admite depósitos y retiros en USD, y habrá un anuncio en el sitio web oficial si se abren otros canales en el futuro. El depósito y retiro mínimo son 10 USD. El depósito y retiro más rápido se realizan en 5 minutos y el más lento en 24 horas. No hay límite en el tiempo de retiro del usuario, puede retirar dinero en cualquier momento. Los retiros llegan en 5 minutos (24 horas en días hábiles normales). Los retiros de fin de semana están programados para procesarse el lunes.',
	BSC提现:"Retiro de USD_BSC",
	社区Ads收益:"Ingresos de Ads comunitarios",
	Ads收益区间:"Ingresos por Ads 1-1000$",
	申请Ads费用:"Tarifa de Ads de aplicaciones",
	Ads促销地址:"URL de promoción de Ads",
	填写Ads促销地址:"Complete la URL de promoción de Ads",
	申请Ads费用须知:"Instrucciones para solicitar tarifas publicitarias",
	须知A:"1. Los usuarios graban videos promocionales y los suben a sus propios canales de YouTube, Facebook u otras comunidades para la promoción de anuncios, solicitan un bono de $ 1- $ 1000 y la plataforma revisará su material de video de anuncios 3 días después de enviar la solicitud y colocará los anuncios. bonificación en la cuenta de acuerdo con el efecto de la promoción del video. El saldo de su cuenta, los videos sin efectos promocionales no se pueden revisar.",
	须知B:"2. El mismo video promocional de anuncios en el mismo canal solo puede solicitar una bonificación una vez, y los nuevos materiales de video promocional se pueden lanzar una vez a la semana. No envíe solicitudes repetidas maliciosas. Si envía de forma malintencionada solicitudes de anuncios no realizadas por usted mismo en la plataforma varias veces o materiales de anuncios que no se revisan, su cuenta será bloqueada después de que el sistema detecte",
	须知C:"3. El material de video de anuncios grabado por el usuario debe tener una introducción básica de la plataforma y una explicación de la función de la plataforma. El tiempo del video no es inferior a 5 minutos. Cuantos más suscriptores tenga su canal, mejor será el efecto promocional del video y mayor será la Tarifa de anuncios que puede solicitar. Las tarifas específicas de anuncios son las siguientes, tomando YOUTUBE como ejemplo:",
	须知D: "Suscriptor 0-2K: monto de la solicitud 1-20 $",
	须知D1:"Suscriptor 3K-10K: cantidad de solicitud 21-40$",
	须知D2:"Suscriptor 11K-30K: cantidad de solicitud 41-100$",
	须知D3:"Suscriptor 31K-50K: cantidad de solicitud 101-300$",
	须知D4:"Suscriptor 51K-100K: cantidad de solicitud 301-1000$",
	须知D5:"4. El sistema revisará y procesará las solicitudes de bonificación de anuncios todos los domingos, y los usuarios calificados recibirán las bonificaciones enviadas por el sistema.",
	请求审核:"Solicitar revisión",
	申请金额:"Monto de la aplicación",
	URL促销地址:"URL promocional",
	提交时间:"Solicitar tiempo",
	需要更新的银行卡不存在:"La tarjeta bancaria a actualizar no existe",
	银行卡提现:"Retiro de efectivo con tarjeta bancaria",
	预计收入:"Ingresos estimados",
	总投资:"Inversión total",
	购买价:"Precio de compra",
	邀请:"Invitar",
	成员:"miembros",
	积分等级折扣规则介绍3:"Este importe de descuento sólo es válido hasta el 31 de diciembre de 2023, después del 1 de enero de 2024 habrá un nuevo ajuste.",
	LV1佣金:"Comisión NIVEL 1",
	LV2佣金:"Comisión NIVEL 2",
	LV3佣金:"Comisión NIVEL 3",
	合格条件:"Depósito mínimo 8$ por persona",
	随时可退:"Vuelve cuando quieras",
	登录密码错误:"Contraseña de inicio de sesión incorrecta",
	充值奖金:"Depósito USD≥50USD, bono 3%",
	充值奖金1:"Depósito IDR≥50USD, bono 2%",
	充值奖金2:"Depósito THB≥50USD, bono 5%",
	活动已过期:"El evento ha caducado, esperando el próximo evento.",
	不能重复发送短信:"No puede enviar mensajes de texto repetidos en 10 minutos. Se recomienda utilizar CAPTCHA DE CORREO ELECTRÓNICO para registrarse directamente.",
	限购说明1:"Hola, cada usuario del paquete de estrategia comercial de robots cuantitativos NO.0 solo puede comprarlo una vez.",
	修改钱包地址提示:"La dirección de retiro de USD no se puede modificar una vez vinculada. Si necesita ayuda, comuníquese con el servicio de atención al cliente.",
	修改银行提示:"La cuenta bancaria de retiro no se puede modificar una vez vinculada. Si necesita ayuda, comuníquese con el servicio de atención al cliente.",
	注册推送消息1:"Bienvenido a INGSAI. Después de que los nuevos usuarios inicien un nodo de minería POS, recibirán ingresos de minería POS cada 5 minutos. Los ingresos por comisiones del equipo se ingresarán automáticamente en el saldo de la cuenta aproximadamente a las 00.00 horas del día.",
	限购说明:"Hola, el número máximo de compras para este tipo de paquete de robot cuantitativo es 10 veces. Se recomienda comprar otros tipos de paquetes de comercio cuantitativo.",
	注册协议:"Al pulsar (Crear Cuenta), confirmas que eres mayor de 18 años. Usted es consciente de que Plus500AI puede utilizar su dirección de correo electrónico u otros datos personales para contactarlo electrónicamente y brindarle información relevante sobre sus productos/servicios. También podemos notificarle sobre eventos del mercado para ayudarlo a aprovechar al máximo su experiencia comercial. Puede administrar y modificar las preferencias de notificación en las pestañas (Configuración de notificaciones) y (Configuración de privacidad) de la Plataforma. Podrás darte de baja de estas comunicaciones en cualquier momento. Para obtener más información, consulte nuestro (Acuerdo de Privacidad)",
	公司说明1:"Plus500AI es una marca registrada de Plus500 Ltd. Plus500 Ltd opera a través de las siguientes subsidiarias:",
	公司说明2:"Plus500SEY Ltd está autorizada y regulada por la Autoridad de Servicios Financieros de Seychelles (Licencia No. SD039).",
	公司说明3:"Plus500SEY Ltd es el emisor y vendedor de los productos financieros descritos o disponibles en este sitio web.",
	公司说明4:"Plus500UK Ltd está autorizada y regulada por la Autoridad de Conducta Financiera. Número de registro de la Autoridad de Conducta Financiera: 509909. Los CFD sobre criptomonedas no están disponibles para usuarios minoristas.",
	公司说明5:"Plus500CY Ltd está autorizada y regulada por la Comisión de Bolsa y Valores de Chipre (código de autorización: 250/14). Los CFD sobre criptomonedas no están disponibles para inversores minoristas del Reino Unido.",
	公司说明6:"Plus500AU Pty Ltd posee las siguientes licencias: AFSL (n.º 417727) emitida por ASIC, FSP (n.º 486026) emitida por la FMA de Nueva Zelanda y Proveedor autorizado de servicios financieros (n.º 47546) emitida por la FSCA de Sudáfrica.",
	公司说明7:"Plus500EE AS ​​​​está autorizada y regulada por la Autoridad de Supervisión Financiera de Estonia (Licencia No. 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) posee una licencia de servicios de mercados de capitales emitida por la Autoridad Monetaria de Singapur, que le permite comercializar productos del mercado de capitales (número de licencia CMS100648).",
	公司说明9:"Plus500AE Ltd está autorizada y regulada por la Autoridad de Servicios Financieros de Dubai (F005651).",
    加入我们的社群:"Únete a nuestra comunidad",
	PLUS500集团:"GRUPOS PLUS500",
	受监管的操作:"OPERACIONES REGULADAS",
	健全的全球监管框架:"Sólido marco regulatorio global",
	治理和社会责任:"GOBERNANZA Y RESPONSABILIDAD SOCIAL",
	底部说明:"Plus500 sigue dedicada a operar de manera responsable y sostenible en todos los aspectos de su negocio y cree que este enfoque es tanto su deber como una parte esencial de una gestión eficaz. Plus500 está comprometido con una variedad de iniciativas ESG para crear valor tangible para nuestra gente, clientes, comunidades locales y organizaciones benéficas y nuestros accionistas.",
	投资者联系方式:"CONTACTOS PARA INVERSORES",
	投资者关系主管:"Jefe de Relaciones con Inversores",
	AI智慧型量化交易:"Comercio cuantitativo inteligente con IA",
	订单执行快速可靠:"La ejecución de órdenes es rápida y confiable",
	全天候自动分析行情:"Análisis de mercado automático las 24 horas del día",
	交易受监管低风险:"El comercio está regulado y es de bajo riesgo.",
	稳定策略高效收益:"Estrategia estable y retornos eficientes",
	取款快速且安全:"Retirar dinero de forma rápida y segura",
	游戏说明:"El saldo mínimo requerido es 10 USD para ingresar al juego y ganar dinero.",
	
	货币:"EUR",
	能量值:"Valor energético",
	财富值:"valor de la riqueza",
	佣金:"comisión",
	累计工资:"Salario acumulado",
	工资:"salario",
	租赁:"alquiler",
	去租赁:"Ir al alquiler",
	
	当前速率:"tasa actual",
	当前工资:"salario actual",
	预计今日收益:"Ganancias estimadas hoy",
	累计佣金:"Comisión acumulada",
	邀请好友注册领取工资:'Invita a amigos a registrarse y recibir salario.',
	月:'luna',
	符号:'es',
	转盘抽奖:'Lotería de ruleta',
	剩余抽奖次数:'Número de sorteos restantes',
	关闭:'cierre',
	恭喜您获得:'Felicitaciones por conseguir',
	很遗憾没有中奖:'Es una pena que no haya ganado.',
	游戏规则:'reglas del juego',
	租赁机器人后会获得抽奖次数:'Después de alquilar el robot, obtendrás el número de sorteos de lotería.',
	中奖金额会存入余额:'El monto ganador se acreditará en su saldo.',
	中奖记录请到收入记录中查看:'Consulte el registro de ingresos para conocer los registros ganadores.',
	
	可购余额:'Saldo disponible para compra',
	可提现余额:'Saldo disponible para retiro',
	总余额:'Saldo total',
	去划转:'Ir a transferir',
	余额不足:'Saldo insuficiente',
	小于最低划转金额:'Menos que el monto mínimo de transferencia',
	余额划转:'Transferencia de saldo',
	可划转余额:'Saldo transferible',
	划转金额:'Monto de la transferencia',
	请输入划转金额:'Por favor, ingrese el monto de la transferencia',
	确认划转:'Confirmar transferencia'
}
