export default {
  tabbar: {
    首页: 'Maison',
    市场: 'marché',
    任务: '任务',
    团队: 'équipe',
    我的: 'compte'
  },
  navbar: {
    充值: 'Recharger',
    提现: 'Retirer',
    帮助: 'FAQ',
    分享: 'Inviter',
	推广: 'Revenus Ads',
    活动: 'Bonus',
    游戏: 'Gibier',
  },	 
  payment: {
    付款方式详情: 'Détails du mode de paiement',
    银行卡: '银行卡',
    钱包地址: 'adresse de portefeuille',
    交易密码: 'mot de passe des transactions',
    保存: 'sauver'
  },
  market: {
    市场: 'Marché',
    名称: 'Nom',
    价格: 'Prix',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"Invitez des amis à s'inscrire et à recevoir des commissions",
  领取:"Recevoir",
  未满足条件:"Condition non remplie",
  总收入:"Revenu total",
  投资建议:"※ Réaliser un plan d'investissement à intérêts composés pour obtenir des rendements plus substantiels",
  语言切换: 'Changer de langue',
  POS区块节点挖矿: 'Détails du trading quantitatif Ai',
  节点类型: 'Robot\nTaper',
  加入价格: 'Acheter\nPrecio',
  质押资金: 'Fonds\nPOS',
  质押周期: 'Période\n(jours)',
  每日收入: 'Revenu\nquotidien',
  累计积分: 'Points\naccumulés',
  代理等级: 'Niveau\nagent',
  下级折扣比: 'Remise\nsubordonnée',
  代理折扣比: 'Agent\nRemise',
  下属等级: 'Niveau\nsubordonné',
  代理佣金比率: 'Agent\nCommission\nTaux',
  下属每日收入: 'Revenu\nquotidien\nsubalterne',
  代理佣金: 'Commission\nagent',
  引用对象: 'référence\nobjet',
  积分等级折扣规则: 'Règles de réduction des niveaux de points',
  积分等级折扣规则介绍: "Les utilisateurs qui participent avec succès au trading quantitatif Ai recevront des points de compte correspondants, et leurs utilisateurs supérieurs d'agent pourront également augmenter les points correspondants (applicables à 15% des subordonnés directs). Lorsque le total des points de compte remplit les conditions correspondantes, le niveau de l'agent Le compte sera amélioré. , Les comptes d'agent à différents niveaux peuvent bénéficier de différentes proportions de remises de prix. Les détails des réductions de points pertinentes sont les suivants :",
  积分等级折扣规则介绍2:"Les utilisateurs ayant des relations différentes (directes) bénéficieront de remises et de remises au niveau des agents en fonction de la participation des subordonnés aux transactions quantitatives Ai. D'autres cadres supérieurs bénéficieront également de réductions proportionnelles à leurs propres agents. Remises de prix pour la participation à des transactions quantitatives Ai : les utilisateurs avec les niveaux d'agent correspondants recevront des droits préférentiels de niveau agent correspondants lorsqu'ils participeront à des transactions quantitatives Ai.",
  团队: 'équipe',
  代理佣金制度: "Système de commission d'agence",
  代理佣金说明详情: "Proportion d'agents LV1 obtenus par A : 8 %, ratio d'agents LV2 obtenus par A : 3 %, ratio d'agents LV3 obtenus par A : 2 %. Exemple : Le bénéfice quotidien de B, C et D est : 100 USD, et la récompense d'agent LV1 obtenue par A : 8 %*100=8 USD. La récompense de l'agent LV2 obtenue par A : 3 %*100=3 USD. La récompense d'agent LV3 obtenue par A : 2%*100=2 USD. Le revenu total des commissions est de : 8+3+2=13 USD.",
  代理结构图示: 'Diagramme de structure de proxy',
  代理结构图示说明: "A vous représente, A invite B (le subordonné de A est B), B invite C (le subordonné de B est C) et C invite D (le subordonné de C est D). A peut avoir plusieurs subordonnés (B1, B2, B3, B4, B5, B6, etc.), B (B1, B2, B3) peut également avoir plusieurs subordonnés (C1, c2, c3, c4, c5, c6, etc. ) C... D...",
  高级代理佣金说明: 'Instructions relatives à la commission des agents principaux',
  高级代理佣金说明详情: "A vous représente, A invite B (le subordonné de A est B), B invite C (le subordonné de B est C) et C invite D (le subordonné de C est D). L'agent supérieur obtiendra différents revenus de commission d'agence (%) en fonction du rapport entre le montant total de l'extraction de nœuds impliqué dans son propre compte et ses comptes subordonnés. A compare le revenu quotidien des membres subordonnés pour obtenir le ratio correspondant des récompenses de l'agence. Les nœuds participants doivent être opérationnels. Les revenus de l'agence sont fournis par INGSAI MINING pour toutes les dépenses financières et n'affecteront pas les revenus quotidiens des membres subordonnés normaux.",
  高级代理佣金说明详情2: "Exemple : Le montant minier total des agents seniors (A) > 30 % du montant minier total des membres inférieurs (B/C/D) (Exemple : A > 30 % de B/C/D)",
  积分: 'points',
  规则: 'règle',
  会员级别: "niveau d'adhésion",
  经验: 'points totaux',
  代理信息: 'informations sur les membres',
  更多: 'Plus',
  团队奖励: "commission d'équipe",
  昨天: 'hier',
  本星期: 'cette semaine',
  全部: 'tous',
  机器人说明: "Les utilisateurs de Plus500Ai peuvent acheter plusieurs packages de trading quantitatifs en même temps, et le robot NO.1 ne peut acheter que 10 fois par compte. N'oubliez pas : n'oubliez pas de cliquer pour activer la stratégie après l'achat du robot quantitatif, sinon il n'y aura aucun revenu. La durée de validité du robot quantitatif est calculée en fonction du moment de l'achat. Veuillez activer la stratégie de trading quantitatif à temps. Si le robot quantitatif n'a pas été activé après son expiration, vous perdrez le coût d'achat du package de stratégie de trading quantitatif ! !!Tous les utilisateurs participant au trading quantitatif Plus500Ai doivent lire attentivement ce règlement ! !",
  会员体验: 'Expérience membre',
  机器人采购: 'Achat de robots',
  机器人启动押金: 'Montant du nœud POS',
  机器人礼物: 'cadeau de nœud',
  机器人订单: 'Ordre des nœuds',
  一次性总和: 'Afficher',
  总计: 'total',
  
  被激活: 'déjà-POS',
  工作中: 'ACTIVÉ',
  暂停: 'Suspendre-POS',
  结束: 'maturité',
  刷新: 'rafraîchir',
  来源: 'la source',
  价格: 'Prix du forfait robot',
  启动押金: 'POS-la quantité',
  剩余时间: 'temps restant',
  激活时间: "Temps d'activation",
  号: 'NO.',
  我的资产: "mon solde d'actifs",
  退出: 'se déconnecter',
  邀请码: "Code d'invitation",
  我的钱包余额: 'le solde de mon portefeuille',
  我的机器人仓库: 'Ma Liste De Robots',
  收益面板: 'Panneau des gains',
  去提款: "retirer de l'argent",
  昨天代理返利: "Commission d'agence hier",
  累计代理返利: "Commission d'agence cumulée",
  今天机器人收益: "Bénéfice commercial du jour",
  累计机器人收益: 'Revenu commercial cumulé',
  累计机器人回扣: 'Commission de négociation accumulée',
  累计总回报: 'Revenu total cumulé',
  自动订单机器人数量: 'Robot de trading quantitatif activé',
  我的付款方式: 'mon moyen de paiement',
  个人收入记录: 'revenu personnel',
  团队收入记录: "revenu de l'équipe",
  记录详情:'Enregistrer les détails',
  充值记录: 'Recharger les dossiers',
  我的团队成员: 'les membres de mon équipe',
  活动中心: 'Présentation des règles',
  修改登录密码: 'mot de passe de connexion',
  交易密码管理: 'mot de passe transactionnel',
  机器人: "Stratégie de trading de l'IA",
  机器人性能: 'Panneau Robot',
  机器人价格: 'Prix de la stratégie de trading',
  有效期: 'Période de validité',
  钱包地址: 'adresse de portefeuille',
  天: 'jour',
  去购买: 'Achat',
  买个机器人: 'Acheter le robot de trading Quant',
  我的钱包余额: 'le solde de mon compte',
  预计日收益: 'Revenu quotidien',
  启动保证金: 'Dépôt de démarrage',
  请输入购买数量: 'Veuillez entrer la quantité achat',
  个机器人: 'Robot de trading quantitatif',
  机器人购买后需要激活才能工作: "Après l'achat d'un nœud, le montant correspondant de POS est requis pour commencer à bénéficier",
  机器人激活需要从余额中扣除相应的启动押金:
    "L'exploration de nœuds POS doit déduire le montant POS correspondant du solde du compte",
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    "La quantité de POS qui peut être restituée immédiatement lorsque l'extraction du nœud POS est suspendue",
  购买: 'Acheter',
  点击去了解我们: 'Commerce Quantitatif',
  购买机器人: 'Achetez un robot',
  快速入口: 'Entrée rapide',
  '日/个人受益': 'Revenu quotidien/personnel',
  购买教程: 'Liste des robots de trading AI',
  激活金额: 'FRAIS DE POS',
  订单数量: 'quantité de commande',
  我们的合作伙伴: 'Nos partenaires du marché de la cryptographie',
  提款: "retrait",
  提款金额: 'montant de retrait',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: 'Instructions de retrait',
  说明1: '1.Vous pouvez sélectionner en un clic ou entrer manuellement le montant de la recharge',
  说明2: "2.Il est recommandé d'utiliser USD (TRC20), les frais de transfert sont les plus bas",
  说明3: "3. Les heures d'ouverture du service client sont du lundi au vendredi AM8 à PM6, heure d'Amérique centrale",
  说明4: "Les utilisateurs qui utilisent USD pour recharger doivent télécharger la capture d'écran correcte du transfert réussi et du TXID, et le compte sera reçu avec succès une fois que la plate-forme aura passé l'examen.",
  说明5: "4. La recharge minimale est de 8 USD, ce qui est pratique pour que le système examine et traite rapidement le compte",
  说明6: "5.Si vous avez des questions sur la recharge, veuillez contacter le service client en ligne à temps pour obtenir de l'aide.",
  确认提款: 'Confirmer le retrait ',
  请输入手机号码:'numéro de téléphone',
  请输入提现金额: 'Montant de retrait ',
  提现费:'Frais de retrait',
  提现须知: 'Veuillez lire attentivement les règles de retrait',
  TIPS1: "1 : L'heure d'examen des retraits quotidiens est du lundi au vendredi AM8 à PM6. Les retraits peuvent être effectués le week-end et l'heure d'examen des retraits est le lundi",
  TIPS2: "2 : système 7X24 heures, retirer de l'argent à tout moment, arriver au compte dans les 24 heures au plus tard et arriver au compte dans les 5 minutes au plus court",
  TIPS3: "3 : Heure du service client en ligne : du lundi au vendredi AM8 à PM6",
  TIPS4: "4 : En raison du coût du transfert USD, la plateforme facture des frais de retrait lorsque l'utilisateur retire de l'argent",
  TIPS5: '5 : Le montant minimum de retrait est de 10 USD',
  钱包余额: 'solde du portefeuille',
  输入数量:"Entrez la quantité",
  数量:"quantité",
  手机号码:"numéro de téléphone",
  手机号码已存在:"Le numéro de téléphone portable a été enregistré",
  邮箱:"E-MAIL",
  输入手机号码: 'numéro de téléphone',
  请输入登录密码: 'mot de passe',
  登录: 'Se connecter',
  输入账号: 'Entrez le numéro de compte',
  密码: 'mot de passe',
  点击注册: "Vous n'avez pas de compte ? Créez-en un maintenant !",
  忘记密码: 'oublier le mot de passe',
  重新登录: 'reconnectez-vous',
  密码找回成功: 'Mot de passe récupéré avec succès',
  找回成功请重新登录: 'Récupérez avec succès, veuillez vous reconnecter',
  确认: 'confirmer',
  发送验证码: 'Envoyer le code de vérification',
  再次输入密码: 'Confirmez le mot de passe',
  输入验证码: "Entrez le CAPTCHA de l'e-mail",
  输入手机验证码:"Entrez l'OTP mobile",
  输入手机号: 'numéro de téléphone',
  输入密码: 'mot de passe',
  快速注册: 'Inscription rapide',
  注册: 'enregistrer',
  立即登录: 'Cliquez pour vous connecter',
  请输入邮箱: 'Adresse Gmail',
  输入用户名: "Entrez votre nom d'utilisateur",
  请输入邀请码: "Entrez le code d'invitation",
  返回: 'revenir',
  密码找回失败: 'La récupération du mot de passe a échoué',
  输入的信息有误: "L'information saisie est incorrecte",
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Le numéro de téléphone ne peut pas être vide",
	账号不存在:"Le compte n'existe pas",
	发送成功:"Bien envoyé",
	今日收益:"（revenu courant）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Bonjour, si vous résiliez le point de vente à mi-chemin, le système facturera des frais de gestion de point de vente de 5 %. Rappel spécial : après la suspension du point de vente de l'expérience de nœud gratuit, vous ne pourrez plus l'obtenir, et vous ne pourrez plus percevoir de frais ! !",
	我同意:"Je suis d'accord",
	用户账号ID:"ID de compte",
	取消:"Cancel",
	日收益:"Revenu quotidien",
	请输入购买数量:"Veuillez entrer la quantité d'achat",
	数量不能为空:"La quantité ne peut pas être vide",
	加载中:"Chargement...",
	唯一码:"Codage des robots",
	未激活:"INACTIF",
	激活:"Démarrer la stratégie",
	购买时间:"temps d'achat",
	"钱包余额不足，激活该机器人失败":"Solde de compte insuffisant, l'extraction de nœud POS ne peut pas être effectuée",

	运行时长:"Durée",
	签约购买:"achat de solde",
	系统赠送:"Cadeau système",
	状态:"État",
	正常:"normal",
	我的机器人:"Mon robot de trading quantitatif",
	一级会员:"Niveau 1",
	二级会员:"Niveau 2",
	三级会员:"Niveau 3",
	人:"",
	充值客服:"充值客服",
	充值:"recharger",
	提现:"retirer",
	提款密码:"Code de retrait",
	设置交易密码:"Définir le mot de passe de retrait",
	登录密码:"mot de passe",
	请输入交易密码:"Entrez le mot de passe de retrait",
	再次请输入交易密码:"Confirmer le mot de passe de retrait",
	确认: 'confirmer',
	手机号码不能为空:"Le numéro de téléphone ne peut pas être vide",
	两次密码不一致:"les deux mots de passe ne correspondent pas",
	请输入验证码:"veuillez entrer le code de vérification",
	操作成功:"Opération réussie",
	"用户名或密码不正确,登录失败":"Le nom d'utilisateur ou le mot de passe est incorrect, la connexion a échoué",
	登录成功:"connexion réussie",
	充值说明:"Consignes de recharge",
	请先设置支付密码:"Veuillez d'abord définir le mot de passe de la transaction",
	复制成功:"copier avec succès",
	冻结机器人做单本金:"Geler les fonds quantitatifs",
	待审核:"en attente d'examen...",
	成功:"succès",
	失败:"échouer",
	审核中:"à l'étude",
	在线充值:"Recharger en ligne",
	描叙:"décrire",
	POS节点挖矿LV0:"POS-LV0 Minage",
	POS节点挖矿LV1:"POS-LV1 Minage",
	POS节点挖矿LV2:"POS-LV2 Minage",
	POS节点挖矿LV3:"POS-LV3 Minage",
	POS节点挖矿LV4:"POS-LV4 Minage",
	POS节点挖矿LV5:"POS-LV5 Minage",
	银行卡提现:"银行卡提现",
	USD提现:"Retrait USD",
	三级代理:"Agent niveau 3",
	一级代理:"Agent niveau 1",
	二级代理:"Agent niveau 2",
	一级:"Niveau 1",
	二级:"Niveau 2",
	三级:"Niveau 3",
	做单:"mineur de nœud",
	登录失败:"échec de la connexion",
	请勿重复操作:"Ne répétez pas les opérations fréquentes",
	邀请码不正确:"le code d'invitation est incorrect",
	团队返佣:"commission d'équipe",
	购买机器人返佣:"Commission robotique",
	本金返还:"Retour POS",
	佣金收入:"Revenu des commissions",
	时间:"temps",
	机器人做单返还:"POS remboursement",
	涨幅:"augmenter",
	市场:"marché",
	客服:"Servir",
	验证码错误:"Erreur de code de vérification",
  付款方式:"Adresse de retrait USD",
	我的留言:"mon message",
  暂无数据: 'Pas de données',
  银行卡:'Compte bancaire',
  修改: 'Réviser',
 "请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助":"Veuillez vous assurer que l'adresse de votre portefeuille est correcte. Si votre adresse est saisie de manière incorrecte et que l'argent ne peut pas être reçu, veuillez contacter le service client en ligne à temps pour obtenir de l'aide.",
 连接借记卡:"连接借记卡",
 银行名称:'Nom de banque',
 姓名姓氏: 'Nom',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':"*Rappel important : les informations relatives au compte bancaire doivent être véridiques et valides avant que vous puissiez retirer de l'argent.",
 帮助中心:"centre d'aide",
 会员须知:'Avis aux membres',
 '24小时内使用USD实时取款（推荐）':'1. 24小时内使用USD实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Je vois',
 链接点击:"Lien vers le registre de référence",
 确定:'Bien sûr',
 建立自己的团队邀请:'construisez votre propre équipe',
 此账号已冻结:"Ce compte a été gelé",
 手机号存已存在:"Le numéro de téléphone portable existe déjà",
 注册账号已存在:"Le compte enregistré existe déjà",
 请确定新密码:"Veuillez confirmer le nouveau mot de passe",
 请再次输入登录密码:"confirmer le mot de passe de connexion",
 密码长度不能少于6位:"La longueur du mot de passe ne peut pas être inférieure à 6 caractères",
 加入:'se joindre à',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Le compte bancaire a été utilisé",
 	USD_TRC20提现:'Retrait USD_TRC20',
 	USD_ERC20提现:'Retrait USD_ERC20',
 	银行卡提现:'Retrait de compte bancaire',
 	邀请赠送:"Prime d'invitation",
 	机器人返佣:'Commission robotique',
 	升级:'achat réussi',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "NO. de transaction",
   消息详情:"Détails du message",
   个人收益累计:"revenus personnels cumulés",
   今日代理收益:"Les revenus de l'agence aujourd'hui",
   代理累计收益:"Revenu cumulé de l'agence",
   截图保存推荐给朋友:"Partagez votre lien d'inscription de parrainage et invitez des amis à rejoindre la plateforme Plus500Ai pour gagner des commissions sur les revenus d'équipe. La commission sur les revenus des membres de l'équipe de troisième niveau est de 8 % à 3 % à 2 %. La commission d'équipe est basée sur les données de revenus quotidiens des membres de niveau inférieur et sera automatiquement réglée et inscrite dans le solde de votre compte après minuit chaque jour.",
   复制:"copie",
   充值提示1:"Astuce 1 : La recharge minimum est de 10 USD, vous pouvez recharger, sinon vous ne serez pas crédité",
   充值提示2:"Astuce 2 : La recharge doit être confirmée sur la chaîne. Après une recharge réussie, elle devrait arriver dans le solde du portefeuille dans environ une minute.。",
   请输入正确的充值金额:"Montant du dépôt ≥ 8 USD",
   推荐:"recommander",
   充值金额:"Montant de la recharge",
   请上传付款截图:"Veuillez télécharger une capture d'écran du paiement réussi",
   交易号: 'Numéro de hachage de la transaction',
   充值ID提示:"Veuillez coller votre numéro Txid",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Veuillez vous assurer que l'adresse du portefeuille est correcte. Si l'adresse du portefeuille est mal remplie et que la perte causée par l'impossibilité de recevoir de l'argent sera à la charge de l'utilisateur。",
	上传凭证:"Télécharger une preuve de paiement",
	充值确认: "soumettre",
	不能为空:"Ne peux pas être vide",
  绑定钱包地址:"Lier l'adresse du portefeuille",
	备注:"备注",
	请输入银行卡号:"Entrez le numéro de compte bancaire",
	请输入你的名字:"Entrez votre nom",
	银行卡号:"Compte bancaire",
	添加银行卡:"Lier le compte bancaire",
	请选择银行:"Sélectionnez une banque",
	请输入钱包地址:"Entrez l'adresse du portefeuille",
	钱包地址:"adresse de portefeuille",
	"密码错误次数过多,请等待x秒后再试":"Trop de mots de passe erronés, veuillez patienter 600 secondes et réessayer",
	"此账号已冻结,请联系客服":"Ce compte a été gelé, veuillez contacter le service client",
	手机号格式错误:"Numéro de téléphone mal formé",
	"取款须知":"Avis de retrait",
	"须知1":"1. Utilisez USD-TRC20 pour un retrait en temps réel dans les 24 heures (recommandé)",
	"须知2":"2. Retrait le samedi et le dimanche",
	"须知3":"*Les utilisateurs peuvent retirer de l'argent le samedi et le dimanche",
	"须知4":"*Les retraits du week-end arriveront le lundi de 10h à 20h",
	我知道了:"Je vois",
	"提现0":"1USD=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USD/天，单笔交易手续费5USD/次",
	"提现2":"TRC20提示2：每次提款最低10USD，每次交易手续费1USD。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"bondir",
	去设置:"Aller aux paramètres",
	注册成功:"succès de l'inscription",
	汇率:"taux de change",
	请输入正确的提现金额:"Veuillez entrer le montant de retrait correct",
	提款记录:"Historique des retraits",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Veuillez d'abord lier l'adresse du portefeuille",
	输入邮箱:"Entrez l'e-mail",
	邮箱已存在:"la boite aux lettres existe déjà",
	注册邮箱已存在:"L'e-mail enregistré existe déjà",
	指定用户不存在:"L'utilisateur demandé n'existe pas",
	今日涨幅:"changer",
	恭喜新用户:"Félicitations nouvel utilisateur ",
	快捷充币:"dépôt",
	快速买USD:"Recharge USD",
	在线支付:"Paiement en ligne",
	自动到账:"Compte automatique",
	线下支付:"线下支付",
	联系客服获取验证码:"联系客服获取验证码",
	获取:"获取",
	退出成功:"quitter avec succès",
	不能低于最小充值:"Ne peut pas être inférieur à la recharge minimale",
	不能低于最小提现金额:"Ne peut être inférieur au montant minimum de retrait",
	最小提现:"Retrait minimal",
	设置成功:"défini avec succès",
	官方充币:"système de dépôt",
	银行卡充值:"Recharge bancaire",
	等待审核:"ATTENDEZ L'EXAMEN",
	可提现金额不足:"Montant insuffisant disponible pour le retrait",
	未经授权:"non autorisé",
	交易密码不正确:"Le mot de passe de la transaction est incorrect",
	提现次数不足:"Délais de retrait insuffisants",
	官网:"官网",
	账户USD不足:"Le solde du compte USD est insuffisant",
	激活成功: "Succès POS",
	操作成功: "Opération réussie",
	修改成功:"Modifié avec succès",
	注册说明:"Mobile OTP et Email CAPTCHA n'ont besoin que de choisir une méthode de vérification et vous pouvez vous inscrire avec succès",
	弹窗内容:
	"Bienvenue sur la plateforme de trading quantitatif Plus500Ai. Chaque utilisateur peut utiliser le bonus d'inscription de 1 USD pour acheter le package de stratégie de trading robot quantitatif NO.0, valable 1 jour et gagner 0,5 USD gratuitement. D'autres packages de stratégies de trading quantitatives nécessitent l'utilisation de frais correspondants. Une fois que les utilisateurs ont acheté le robot, ils doivent activer la stratégie de trading quantitative avant qu'elle puisse démarrer automatiquement. Chaque stratégie de trading correspond à une période de validité différente. Si l'utilisateur ne clique pas pour activer la stratégie après avoir acheté les points robot correspondants, vous ne recevrez aucun revenu de trading.",
	标题1:'1.Présentation de la plateforme Plus500',
	标题2:"2. Avantages du trading quantitatif de l'IA",
	标题3:'3. Pourquoi choisir Plus500Ai ?',
	标题4:'4. Comment rejoindre Plus500Ai',
	标题5:"5.Perspectives du marché Plus500Ai",
	标题6:'6.Modèle de revenus Plus500Ai',
	标题7:'7.Règlement sur les dépôts et les retraits',
	标题8:"Jouabilité de Battle Royale",
	内容8:"Il y a 8 salles dans le jeu Battle Royale sur la plateforme Plu500Ai. Les joueurs peuvent choisir n'importe quelle salle parmi les 8 salles pour y placer des pièces d'or. Une fois le compte à rebours du système terminé, un tueur apparaîtra dans le système de jeu. Le tueur entre au hasard dans une pièce. et élimine les personnes présentes dans la pièce. D'autres joueurs peuvent partager les pièces d'or dans cette pièce et réaliser des bénéfices. Le gameplay du jeu Battle Royale est relativement court, avec un jeu ne durant que quelques minutes, afin que les joueurs puissent y jouer pendant leur temps libre. Le montant de la division = le nombre total de pièces tuées par le tueur au cours de cette période. Montant réel de l'action = montant de l'action * 0,9 (hors taux de destruction de 0,1). Ratio de partage = montant réel du partage / montant total de la mise dans les autres salles. Montant gagnant = ratio de partage * montant réel de la mise. 1 pièce d'or = 1 USD, et un minimum de 10 USD est requis pour participer au jeu Battle Royale.",
	内容1:"La plateforme de trading Plus500 est fournie par Plus500SEY Ltd. En conséquence, Plus500SEY Ltd est l'émetteur et le vendeur des produits financiers décrits ou proposés sur ce site Internet. Plus500SEY Ltd est une société basée aux Seychelles (société n° 8426415-1) avec ses bureaux à Victoria. La société est autorisée et supervisée par la Seychelles Financial Services Authority (FSA) avec le numéro de licence SD039 et propose des contrats sur différence (CFD) sur divers produits de base. La société est un fournisseur de CFD en croissance rapide qui propose actuellement un portefeuille de plus de 2 800 instruments financiers. Plus500SEY Ltd est une filiale de Plus500 Ltd, cotée sur le marché principal de la Bourse de Londres et dont le siège est à Haïfa.",
	内容2:"L'avantage du trading quantitatif entièrement automatisé par l'IA réside dans l'utilisation d'outils rationnels pour effectuer des transactions en fonction des paramètres du modèle.Le robot ne subit aucun changement émotionnel et peut surmonter la nature humaine, l'avidité et la peur humaines ainsi que de nombreuses autres lacunes et faiblesses du processus opérationnel. Les opérations manuelles sont souvent affectées par la hausse et la baisse du marché et par divers autres facteurs, et ne peuvent pas fonctionner longtemps selon le meilleur modèle de profit.",
	内容3:"Plus500Ai a progressivement renforcé sa position de groupe fintech multi-actifs en étendant ses produits de base sur des marchés nouveaux et existants, en lançant de nouveaux produits commerciaux et financiers et en approfondissant son engagement auprès des clients. Protégez vos actifs et protégez vos clés de chiffrement grâce à une technologie de sécurité de pointe. Plus500Ai propose également une garde mondiale, ce qui signifie que nous sommes réglementés, audités et assurés jusqu'à 250 millions de dollars. Déployez votre capital dans le but non seulement de détenir des actifs, mais aussi de les mettre à profit. Plus500Ai vous permet de déployer vos fonds de différentes manières, du trading quantitatif Ai aux services primaires classiques, en fonction de votre tolérance au risque. Plateforme en ligne pour fournir plus de services à vos propres utilisateurs sans embaucher d'ingénieurs blockchain supplémentaires.",
	内容4:"Depuis la création de Plus500Ai, il n'existe qu'un seul site officiel, WWW.PLUS500AI.COM. Tous les utilisateurs doivent se connecter au site officiel pour télécharger l'application, car les autres sites Web n'appartiennent pas à notre société. Pour vous inscrire, vous devez soumettre le numéro de téléphone mobile de l'utilisateur, son adresse e-mail et le code d'invitation du parrain pour vous inscrire rapidement. Toutes les communications avec les clients se font par écrit, par e-mail ou par chat en ligne. Pour contacter Plus500 par e-mail, veuillez remplir le formulaire (formulaire de candidature) sur la page Contactez-nous. Après avoir soumis le formulaire de candidature, Plus500 enverra une réponse par e-mail directement à l'adresse e-mail que vous avez spécifiée dans le formulaire de candidature. Si vous avez des questions, veuillez contacter le service client.",
	内容5:"Plus500Ai opère sur tous les produits, appareils et pays pour offrir aux clients des opportunités de trading financier fiables et intuitives. Nous avons toujours recherché l'excellence dans nos produits et nos équipes. Grâce à une technologie propriétaire avancée et à une approche centrée sur le client, nos utilisateurs bénéficient d'une expérience de service de trading de première classe.",
	内容6:"Plus500Ai fournit des stratégies de trading quantitatives professionnelles pour le marché des crypto-monnaies par une équipe d'analystes de données senior. Les utilisateurs qui investissent dans le robot de trading quantitatif Ai peuvent obtenir des bénéfices commerciaux quantitatifs en s'appuyant sur les stratégies de trading adaptées au robot. Le robot de trading quantitatif Ai analyse cycliquement le temps de trading optimal sur la base de la stratégie de trading de marché de Plus500Ai et obtient des bénéfices de trading quantitatifs grâce au modèle d'achat et de vente de bas niveau à des prix élevés. Plus500Ai fournit chaque jour aux utilisateurs investisseurs des stratégies de trading quantitatives optimales, et la plateforme extrait 5 % des bénéfices quantitatifs des utilisateurs sous forme de fonds d'exploitation de la plateforme.",
	内容7:"Plus500Ai prend actuellement en charge les dépôts et les retraits USD, et une annonce sera publiée sur le site officiel si d'autres canaux sont ouverts à l'avenir. Le dépôt et le retrait minimum sont tous deux de 10 USD. Le dépôt et le retrait les plus rapides se font dans les 5 minutes et les plus lents dans les 24 heures. Il n'y a pas de limite sur le temps de retrait des utilisateurs, vous pouvez retirer de l'argent à tout moment. Les retraits arrivent dans un délai de 5 minutes à 24 heures les jours ouvrables normaux. Les retraits du week-end doivent être traités le lundi.",
	BSC提现:"Retrait USD_BSC",
	社区Ads收益:"Revenus des annonces de la communauté",
	Ads收益区间:"Revenus publicitaires 1-1000$",
	申请Ads费用:"Frais d'annonces d'application",
	Ads促销地址:"URL de promotion des publicités",
	填写Ads促销地址:"Remplissez l'URL de promotion des annonces",
	申请Ads费用须知:"Instructions pour demander les frais d'annonces",
	须知A:"1. Les utilisateurs enregistrent des vidéos promotionnelles et les téléchargent sur leurs propres YouTube, Facebook, chaînes ou autres communautés pour la promotion des annonces, demandent un bonus de 1 $ à 1000 $, et la plate-forme examinera votre matériel vidéo Ads 3 jours après avoir soumis la demande et mettra les annonces bonus sur le compte en fonction de l'effet de promotion de la vidéo Le solde de votre compte, les vidéos sans aucun effet promotionnel ne peuvent pas être examinées",
	须知B:"2. La même vidéo promotionnelle Ads sur la même chaîne ne peut demander un bonus qu'une seule fois, et de nouveaux supports vidéo promotionnels peuvent être publiés une fois par semaine. N'envoyez pas de demandes répétées malveillantes. Si vous soumettez à plusieurs reprises de manière malveillante des demandes d'annonces que vous n'avez pas faites vous-même sur la plate-forme ou des documents publicitaires qui ne sont pas examinés, votre compte sera banni une fois que le système aura détecté",
	须知C:"3. Le matériel vidéo Ads enregistré par l'utilisateur doit avoir une introduction de base de la plate-forme et une explication de la fonction de la plate-forme. La durée de la vidéo n'est pas inférieure à 5 minutes. Plus il y a d'abonnés à votre chaîne, meilleur est l'effet promotionnel de la vidéo et plus le Frais d'annonces que vous pouvez demander. Les frais spécifiques aux annonces sont les suivants, en prenant YOUTUBE comme exemple :",
	须知D: "Abonné 0-2K : montant de la demande 1-20 $",
	须知D1:"Abonné 3K-10K : montant de la demande 21-40 $",
	须知D2:"Abonné 11K-30K : montant de la demande 41-100 $",
	须知D3:"Abonné 31K-50K : montant de la demande 101-300 $",
	须知D4:"Abonné 51K-100K : montant de la demande 301-1000 $",
	须知D5:"4. Le système examinera et traitera les demandes de bonus Ads tous les dimanches, et les utilisateurs qualifiés recevront les bonus envoyés par le système.",
	请求审核:"demande de révision",
	申请金额:"Montant de l'application",
	URL促销地址:"Promouvoir les URL",
	提交时间:"heure de soumission",
	需要更新的银行卡不存在:"La carte bancaire à mettre à jour n'existe pas",
	银行卡提现:"Retrait d'argent par carte bancaire",
	预计收入:"Revenu estimé",
	总投资:"Investissement total",
	购买价:"prix d'achat",
	邀请:"Inviter",
	成员:"membres",
	积分等级折扣规则介绍3:"Ce montant de réduction n'est valable que jusqu'au 31 décembre 2023, après le 1er janvier 2024 il y aura un nouvel ajustement.",
	LV1佣金:"Commissions NIVEAU 1",
	LV2佣金:"Commissions NIVEAU 2",
	LV3佣金:"Commissions NIVEAU 3",
	合格条件:"Dépôt minimum 8$ par personne",
	随时可退:"Remboursez à tout moment",
	登录密码错误:"Mauvais mot de passe de connexion",
	充值奖金:"Déposez USD≥50USD, bonus 3%",
	充值奖金1:"Déposez IDR≥50USD, bonus 2%",
	充值奖金2:"Déposez THB≥50USD, bonus 5%",
	活动已过期:"L'événement a expiré, en attente du prochain événement",
	不能重复发送短信:"Vous ne pouvez pas envoyer de messages texte répétés dans les 10 minutes. Il est recommandé d'utiliser EMAIL CAPTCHA pour vous inscrire directement.",
	限购说明1:"Bonjour, chaque utilisateur du package de stratégie de trading robot quantitatif NO.0 ne peut l'acheter qu'une seule fois.",
	修改钱包地址提示:"L'adresse de retrait USD ne peut pas être modifiée une fois liée. Si vous avez besoin d'aide, veuillez contacter le service client.",
	修改银行提示:"Le compte bancaire de retrait ne peut pas être modifié une fois lié. Si vous avez besoin d'aide, veuillez contacter le service client.",
	注册推送消息1:"Bienvenue sur INGSAI. Une fois que les nouveaux utilisateurs ont démarré un nœud de minage POS, ils recevront un revenu minier POS toutes les 5 minutes. Les revenus des commissions de l'équipe seront automatiquement inscrits dans le solde du compte vers 00h00 par jour.",
	限购说明:"Bonjour, le nombre maximum d'achats pour ce type de package de robot quantitatif est de 10. Il est recommandé d'acheter d'autres types de packages de trading quantitatif.",
	注册协议:"En appuyant sur (Créer un compte), vous confirmez que vous avez plus de 18 ans. Vous savez que Plus500AI peut utiliser votre adresse e-mail ou d'autres données personnelles pour vous contacter par voie électronique afin de vous fournir des informations pertinentes sur ses produits/services. Nous pouvons également vous informer des événements du marché pour vous aider à tirer le meilleur parti de votre expérience de trading. Vous pouvez gérer et modifier les préférences de notification dans les onglets (Paramètres de notification) et (Paramètres de confidentialité) de la Plateforme. Vous pouvez vous désinscrire de ces communications à tout moment. Pour plus d'informations, veuillez consulter notre (Accord de confidentialité)",
	公司说明1:"Plus500AI est une marque déposée de Plus500 Ltd. Plus500 Ltd opère à travers les filiales suivantes :",
	公司说明2:"Plus500SEY Ltd est autorisée et réglementée par la Seychelles Financial Services Authority (licence n° SD039).",
	公司说明3:"Plus500SEY Ltd est l'émetteur et le vendeur des produits financiers décrits ou disponibles sur ce site.",
	公司说明4:"Plus500UK Ltd est autorisée et réglementée par la Financial Conduct Authority. Numéro d'enregistrement de la Financial Conduct Authority : 509909. Les CFD sur crypto-monnaie ne sont pas disponibles pour les utilisateurs particuliers.",
	公司说明5:"Plus500CY Ltd est autorisée et réglementée par la Securities and Exchange Commission de Chypre (code d'autorisation : 250/14). Les CFD sur crypto-monnaie ne sont pas disponibles pour les investisseurs particuliers britanniques.",
	公司说明6:"Plus500AU Pty Ltd détient les licences suivantes : AFSL (n° 417727) délivrée par l'ASIC, FSP (n° 486026) délivrée par la FMA de Nouvelle-Zélande et fournisseur de services financiers agréé (n° 47546) délivré par la FSCA d'Afrique du Sud.",
	公司说明7:"Plus500EE AS ​​​​est autorisé et réglementé par l'Autorité estonienne de surveillance financière (licence n° 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) est titulaire d'une licence de services sur les marchés des capitaux délivrée par l'Autorité monétaire de Singapour, lui permettant de négocier des produits du marché des capitaux (numéro de licence CMS100648).",
	公司说明9:"Plus500AE Ltd est autorisée et réglementée par la Dubai Financial Services Authority (F005651).",
    加入我们的社群:"Rejoignez notre communauté",
	PLUS500集团:"GROUPE PLUS500",
	受监管的操作:"OPÉRATIONS RÉGLEMENTÉES",
	健全的全球监管框架:"Un cadre réglementaire mondial solide",
	治理和社会责任:"GOUVERNANCE ET RESPONSABILITÉ SOCIALE",
	底部说明:"Plus500 reste déterminé à fonctionner de manière responsable et durable dans tous les aspects de ses activités et estime que cette approche est à la fois son devoir et un élément essentiel d'une gestion efficace. Plus500 s'engage dans une série d'initiatives ESG afin de créer une valeur tangible pour nos collaborateurs, nos clients, les communautés locales, les associations caritatives et nos actionnaires.",
	投资者联系方式:"CONTACTS INVESTISSEURS",
	投资者关系主管:"Responsable des Relations Investisseurs",
	AI智慧型量化交易:"Commerce quantitatif intelligent par l'IA",
	订单执行快速可靠:"L’exécution des commandes est rapide et fiable",
	全天候自动分析行情:"Analyse automatique du marché 24 heures sur 24",
	交易受监管低风险:"Le trading est réglementé et à faible risque",
	稳定策略高效收益:"Stratégie stable et rendements efficaces",
	取款快速且安全:"Retirer de l'argent rapidement et en toute sécurité",
	游戏说明:"Le solde minimum requis est de 10 USD pour entrer dans le jeu et gagner de l'argent.",
	
	货币:"EUR",
	能量值:"Valeur énergétique",
	财富值:"Valeurénergétique",
	佣金:"commission",
	累计工资:"Salaire accumulé",
	工资:"salaire",
	租赁:"location",
	去租赁:"Aller à la location",
	
	当前速率:"taux actuel",
	当前工资:"salaire actuel",
	预计今日收益:"Gains estimés aujourd'hui",
	累计佣金:"Commission accumulée",
	邀请好友注册领取工资:'Invitez des amis à sinscrire et à recevoir un salaire',
	月:'lune',
	符号:'fr',
	转盘抽奖:'Loterie à la roulette',
	剩余抽奖次数:'Nombre de tirages restants',
	关闭:'fermeture',
	恭喜您获得:'Félicitations pour avoir obtenu',
	很遗憾没有中奖:"C'est dommage que je n'ai pas gagné",
	游戏规则:'règles du jeu',
	租赁机器人后会获得抽奖次数:'Après avoir loué le robot, vous obtiendrez le nombre de tirages de la loterie',
	中奖金额会存入余额:'Le montant gagnant sera crédité sur votre solde',
	中奖记录请到收入记录中查看:'Veuillez vérifier le relevé de revenus pour connaître les records gagnants.',
	
	可购余额:'Solde disponible pour achat',
	可提现余额:'Solde disponible pour retrait',
	总余额:'Solde total',
	去划转:'Aller à la transférer',
	余额不足:'Solde insuffisant',
	小于最低划转金额:'Moins que le montant de transfert minimum',
	余额划转:'Transfert de solde',
	可划转余额:'Solde transférable',
	划转金额:'Montant du transfert',
	请输入划转金额:'Veuillez saisir le montant du transfert',
	确认划转:'Confirmer le transfert'
}
